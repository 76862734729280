<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction" />
      </v-col>
    </v-row>
    <ViewDetailsMain class="my-2" :object="objectDetail" type="CajasVentasDetails" :items-action="tableAction" />
    <MainFormDialog
      v-if="get_dialogMain.dialog"
      :model="object"
      :params="paramsDialog"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DataTableMain from '@/components/tables/DataTableMain';
import MainFormDialog from '@/components/dialogs/MainFormDialog';
import ViewDetailsMain from '@/components/details/ViewDetailsMain.view'
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [Permission],
  name: 'VentasCajaView',
  components: {
    DataTableMain,
    MainFormDialog,
    ViewDetailsMain
  },
  data: () => ({
    table: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newData', code: 'caja.options.toCreate' },
          {
            title: 'Reportes',
            icon: 'mdi-cloud-download',
            color: 'light-blue lighten-1',
            code: 'cajas.reporte',
            options: [
              { title: 'Reporte de Cajas en General', icon: 'mdi-clipboard-text', color: 'yellow darken-3', action: 'ExportCajasGral', code: 'venta.caja.general-excel' },
            ]
          }
        ],
        titles: [
          { text: 'Folio', value: 'folio', class: 'black--text' },
          { text: 'Nombre Caja', value: 'nombre', class: 'black--text' },
          { text: 'Sucursal', value: 'caracteristicas.sucursal.nombre', class: 'black--text' },
          { text: 'Asignado', value: 'CajaAsignado', class: 'black--text' },
          { text: 'Estatus', value: 'Status', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: '', value: 'Actions', class: 'black--text' },
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [],
      },
      footer: {
        paging: true,
      },
    },
    paramsDialog: {},
    object: {},
    formas_pago: []
  }),
  computed: {
    ...mapGetters(['get_urls', 'get_dialogMain', 'get_objects', 'get_objectTwo',
      'get_SearchCajasForm', 'get_ShowDetailCajaForm']),
    objectDetail () {
      if (this.table.modelMain.length > 0) return this.table.modelMain[0]
      return {}
    }
  },
  watch: {
    get_urls () { this.refreshData({}) },
    get_objects (rows) {
      this.table.modelMain = []
      if (rows.length > 0) { this.table.modelMain.push(rows[0]) }
      this.table.header.loading = false
      this.table.body.data = rows
      const router = this.get_urls['GET/catalogo/formas-pago']
      this.GETObjectTwoService({ url: router })
    },
    get_objectTwo (val) { this.formas_pago = val }
  },
  mounted () {
    this.refreshData({})
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'GETListObjectsService', 'GETObjectTwoService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    // *==========*==========*==========*==========* \\
    successAction (item) {
      this[item.action](item)
    },
    refreshData (item) {
      this.table.header.loading = true
      this.ExecuteUrl('GET/venta/cajas')
    },
    newData (item) {
      this.object = {};
      this.paramsDialog = {
        urlprocess: this.get_urls['GET/venta/cajas/options[/{status_id}]'],
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'create', form: 'process', with: 850, title: 'Crear Caja',
      });
    },
    tableAction (item) {
      this[item.action](item);
    },
    viewDetailCaja (item) {
      this.object = item.item
      this.paramsDialog = {
        form: this.get_ShowDetailCajaForm,
        message: 'Registro Agregado Correctamente',
        setmodel: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'view', with: '97%', title: 'Detalle de Caja', body:{ isbutton: true }  })
    },
    toAssign (item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({ type: 'create', form: 'process', with: 550, title: 'Asignar Caja' });
    },
    toOpen (item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({ type: 'create', form: 'process', with: 550, title: 'Abrir Caja' })
    },
    toDeposit (item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'create', form: 'process', with: 650, title: 'Deposito Caja',
      });
    },
    toWithdraw (item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'create', form: 'process', with: 650, title: 'Retiro de Caja',
      });
    },
    toRepay (item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'create', form: 'process', with: 850, title: 'Devolucion',
      });
    },
    toBalance (item) {
      this.object = item;
      const ingresos = item.item.last_movement.detalles.filter((mov) => mov.descripcion.descripcion && mov.descripcion.status_id === 24 );
      const egresos = item.item.last_movement.detalles.filter( (mov) => mov.descripcion.descripcion && mov.descripcion.status_id === 25 );
      let totalEgress = 0
      egresos.map(item =>{ totalEgress += item.descripcion.monto });
      if (ingresos.length <= 0) {
        this.$swal({
          type: 'warning',
          icon: 'warning',
          title: 'Advertencia !',
          text: 'La caja no puede cerrar sin un ingreso agregado',
        })
        return
      }
      // console.log('ingresos', ingresos)
      // console.log('egresos', egresos)

      const montos = this.SumaImportesCorte(ingresos, egresos)
      // console.log('montos', montos)

      const base = parseFloat(item.item.last_movement.detalle.descripcion.monto)
      this.formas_pago.map(fp => {
          const nombre = fp.nombre.toLowerCase().replace(' ','_')
          if (!montos[nombre]) montos[nombre] = 0
          if (fp.id == 1){
            montos[nombre] += base
            montos[nombre] -= montos.retiros
          }
        })

      const obj = { importe_retiros: totalEgress, montos: montos, tipo_cierre: 'COMPLETO', ingresos: ingresos, egresos: egresos,
        '10_centavos': 0, '20_nomeda': 0,'20_billete': 0,'50_nomeda': 0,'50_billete': 0,'1_nomeda': 0,'100_billete': 0,
        '2_nomeda': 0,'200_billete': 0,'5_nomeda': 0,'500_billete': 0,'10_nomeda': 0,'1000_billete': 0 }
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
        // returnObject: true,
        setmodel: true,
        data: obj
      };
      this.SHOW_DIALOG_MAIN({ type: 'cierre-caja', form: 'process', with: '60%', title: `Cerrar Caja - ${item.item.CajaAsignado}` });
    },
    SumaImportesCorte (rows, rowse) {
      let globales = { retiros: 0 }
      rows.map(row => {
        let nombre = ''
        let importe = 0
        if (row.descripcion.pago) {
          nombre = row.descripcion.pago.forma_pago.nombre.toLowerCase().replace(' ', '_')
          importe = parseFloat(row.descripcion.pago.importe)
        } else {
          nombre = row.descripcion.forma_pago.nombre.toLowerCase().replace(' ', '_')
          importe = parseFloat(row.descripcion.monto)
        }
        if (globales[nombre]) globales[nombre] += importe
        else globales[nombre] = importe
      })
      // console.log('globales', globales)
      rowse.map(rowe => { globales.retiros += rowe.descripcion.monto })
      return globales
    },
    ExportCajasGral (item) {
      this.object = { nombre: 'Cajas_Generales' }
      this.paramsDialog = {
        form: this.get_SearchCajasForm,
        url: this.get_urls['POST/venta/cajas/reportes/cajas-generales'],
        message: 'Registros no Encontrados',
        exportData: true,
        setmodel: true
        // returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'reporteCajas', with: 650, title: 'REPORTE DE CAJAS' })
    },
    // *==========*==========*==========*==========* \\
    ExecuteUrl (url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url]
        this.GETListObjectsService({ url: router, data: {} })
      } else {
        this.GETUrlServices()
      }
    }
  }
}
</script>

<style>

</style>
