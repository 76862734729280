<template>
  <v-card class="mx-auto" elevation="0" title>
      <v-card-title class="pa-1 ma-1 justify-center"> <h2>{{itemAction.name}}</h2></v-card-title>
      <v-virtual-scroll :items="listMain" :item-height="90" min-height="350" >
        <template v-slot:default="{ item }">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                    {{ itemText(item) }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action >
                <v-text-field v-if="validarRecepcion(item) > 0"
                  v-model.number="item.recepcion_faltante" @keyup="confirmCheck(item)"
                  label="Recepcionar" type="number" min="0" outlined dense />
                  <label v-else>
                    {{ item.cantidad_recepcion }}
                  </label>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
        </template>
      </v-virtual-scroll>
  </v-card>
</template>
<script>
  export default {
    props: ['itemAction', 'onSuccess', 'setterModel'],
    // mixins: [Permission],
    data: () => ({
      listMain: [],
      catalog: [],
      deleteds: [],
      showCostActivity: false,
      searchMain: ''
    }),
    created () {
      this.seterModel(this.setterModel)
    },
    methods: {
      itemText (item) {
        if (item && this.itemAction.nameid === 'productos') {
          if(this.validarRecepcion(item) > 0) {
            return `${item.articulo.caracteristicas.descripcion} - (${this.validarRecepcion(item)} ${item.udm.nombre})`
          }
            return `${item.producto.caracteristicas.descripcion}`
        }
        return `${item.nombre}`
      },
      validarRecepcion (item) {
        item.recepcion_faltante = 0
        return item.cantidad - item.cantidad_recepcion
      },
      seterModel (val) {
        if (val) {
          this.listMain.push(...val)
        }
      },
      confirmCheck (item) {
        if (item.recepcion_faltante > (item.cantidad - item.cantidad_recepcion) ) item.recepcion_faltante = (item.cantidad - item.cantidad_recepcion)

        this.onSuccess({
          id: this.itemAction.nameid,
          data: this.listMain,
          fieldMain: this.itemAction
        })
      }
    },
    watch: {  
    }
  }
  </script>
  
  <style>
  
  </style>
  