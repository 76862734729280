<template>
  <v-container>
    <v-row dense>
      <v-card width="100%" tile>
        <v-card-text >
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <DatePicker :item-action="fieldDateInit" model="fruit" :on-success="actionFieldData" />
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <DatePicker :item-action="fieldDateFinal" :on-success="actionFieldData" />
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <SelectedDinamicField :setter-model="params.sucursal_id" :item-action="SelectedSucursalField" :on-success="actionFieldData" />
            </v-col>
            <v-col cols="12" align="right">
              <PreviewPDF v-if="visor" :itemAction=this.itemAction :setterModel=this.params></PreviewPDF>
            </v-col>
            <v-col cols="12" align="right">
              <v-btn color="success" @click="searchReportUtilidad" dark>
                <v-icon left dark>mdi-file-excel</v-icon>Descargar Excel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-col cols="12">
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SelectedDinamicField from '@/components/fields/SelectedDinamicField'
import DatePicker from '@/components/fields/DatePicker'
import PreviewPDF from '@/components/fields/specific/PreviewPDF'
import { Permission } from '@/mixins/permissionMain'
import { UserSession } from '@/store/modules/config'
export default {
    mixins: [Permission],
    name: 'AlmacenesView',
    components: {
      SelectedDinamicField,
      DatePicker,
      PreviewPDF
    },
    data: () => ({
      fieldDateInit: { field: 'textDatePicker', name: 'Fecha Inicio', nameid: 'fecha_init', default: true, cols: 3 },
      fieldDateFinal: { field: 'textDatePicker', name: 'Fecha Final', nameid: 'fecha_final', default: true,   cols: 3 },
      SelectedSucursalField: { field: 'selectDataServer', name: 'Sucursales', nameid: 'sucursal_id', url: '/empresa/sucursales', parmas: '', cols: 3 },
      params: {
        sucursal_id: 0
      },
      itemAction: {
        route: 'finanzas/rpt/general/pdf'
      },
      visor: false
    }),
    mounted() {
        this.params.sucursal_id = UserSession().sucursal_id
        console.log(this.params)
    },
    methods: {
      ...mapActions(['GETUrlServices', 'ExportDataService']),
      ...mapMutations(['SHOW_DIALOG_MAIN']),
      actionFieldData(obj) {
        this.visor = false
        this.params[obj.id] = obj.data === undefined ? null : obj.data;
        setTimeout(() => {
          this.visor = true
        }, 500)
      },
      ExecuteUrl(url) {
        if (this.get_urls != null) {
          const router = this.get_urls[url];
        } else {
          this.GETUrlServices();
        }
      },
      // *==========*==========*==========*==========* \\
      searchReportUtilidad () {
        if (!this.params.sucursal_id) return
        const router = this.get_urls['POST/finanzas/reportes/utilidades']
        this.params.nombre = 'ReporteUtilidades';
        this.ExportDataService({ url: router, data: this.params, })
      }

    },
    computed: {
      ...mapGetters(['get_urls']),
    },
    watch: {
      get_urls(){
        this.searchReportUtilidad()
      }
    },
  }
  </script>
  
  <style>
  
  </style>
  