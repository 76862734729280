<template>
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-card tile>
            <v-card-text>
              <v-row dense>
                <v-col v-if="showEmpresa" cols="12" sm="6" md="4" lg="3">
                  <SelectedDinamicField :setter-model="params.empresa_id" :item-action="SelectedEmpresasField" :on-success="actionFieldData" />
                </v-col>
                <v-col v-if="showSucursal" cols="12" sm="6" md="4" lg="3">
                  <SelectedDinamicField :setter-model="params.sucursal_id" :item-action="SelectedSucursalField" :on-success="actionFieldData" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <DataTableMain :datatable="table" :on-success="successAction" :itemsAction="tableAction"/>
        </v-col>
      </v-row>
        <MainFormDialog v-if="get_dialogMain.dialog" :model="objectMain" :params="paramsDialog"/>
    </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import MainFormDialog from '@/components/dialogs/MainFormDialog'
import SelectedDinamicField from "@/components/fields/SelectedDinamicField"
import { UserSession } from '@/store/modules/config'
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [Permission],
  data: () => ({
    table: {
      modelMain: [],
      showSelect: false,
      singleSelect: false,
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newData', code: 'empresa.empleado.create-update' }
        ],
        titles: [
          { text: 'Cve Empleado', value: 'cve_empleado', class: 'black--text' },
          { text: 'Empleado', value: 'NombreCompleto', class: 'black--text' },
          { text: 'Empresa', value: 'sucursal.empresa.nombre', class: 'black--text' },
          { text: 'Sucursal', value: 'sucursal.nombre', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: '', value: 'ActionsGral', class: 'black--text', align: 'right' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: [
          { title: 'Activar / Desactivar', icon: 'mdi-checkbox-blank-circle', color: 'grey darken-3', action: 'disableData', code: 'empresa.empleado.up-down' },
          { title: 'Editar', icon: 'mdi-pencil', color: 'amber darken-3', action: 'updateData', code: 'empresa.empleado.create-update' },
          { title: 'Eliminar', icon: 'mdi-delete', color: 'red darken-3', action: 'deleteData', code: 'empresa.empleado.delete' }
        ]
      },
      footer: {
        paging: true
      }
    },
    objectMain: {},
    paramsDialog: {},
    params: {},
    SelectedEmpresasField: { field: 'selectDataServer', name: 'Empresas', nameid: 'empresa_id', url: '/empresa', parmas: '', cols: 3 },
    SelectedSucursalField: { field: 'selectDataServer', name: 'Sucursales', nameid: 'sucursal_id', url: '/empresa/sucursales/list-empresa', parmas: '', cols: 3 },
    showEmpresa: false,
    showSucursal: false
  }),
  components: {
    DataTableMain,
    MainFormDialog,
    SelectedDinamicField
  },
  mounted () {
    this.params.empresa_id = UserSession().empresa_id
    // this.params.sucursal_id = UserSession().sucursal_id
    if (this.showMainPermiss('change.empresa')) {
      this.showEmpresa = true
      // this.showSucursal = true
    }else {
      this.SelectedSucursalField.params = `/${this.params.empresa_id}`
      // this.showSucursal = true
      this.refreshData({})
    }
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'POSTListObjectsService', 'PUTDisableEnableObjectService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    actionFieldData (obj) {
      this.params[obj.id] = (obj.data) ? obj.data : null
      if (obj.id === 'empresa_id') {
        if (obj.data) {
          this.SelectedSucursalField.params = `/${obj.data}`
          this.showSucursal = true
        } else {
          this.SelectedSucursalField.params = ''
          this.showSucursal = false
        }
      }
      this.refreshData({})
    },
    showMainPermiss (permiso) {
      return this.ReadOnlyPermiso(JSON.parse(localStorage.getItem('permisos')), permiso)
    },
    /* ========== ========== ========== ========== ========== ========== ========== ========== */
    successAction (item) {
      this[item.action](item)
    },
    refreshData (item) {
      this.table.header.loading = true
      this.ExecuteUrl('POST/empresa/empleado/params')
    },
    newData (item) {
      this.objectMain = {}
      this.paramsDialog.form = this.get_EmpleadoForm
      this.paramsDialog.url = this.get_urls['POST/empresa/empleado']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', with: 850, title: 'Nuevo Empleado' })
    },
    newDataRoles ({ item }) {
      this.object = {
        id: item.id,
        usuario: item.usuario,
        NombreCompleto: item.empleado.NombreCompleto,
      }
      this.paramsDialog.form = this.get_AssignRoles
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/usuarios/assign-rol']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.paramsDialog.setmodel = true
      this.SHOW_DIALOG_MAIN({ type: 'assignrol', with: 850, title: `Asignar Rol (${item.empleado.NombreCompleto})` })
    },
    daleteDataRol ({ item, model }) {
      // console.log('model', model)
      // console.log('item', item)
    },

    disableData  ({ item }) {
      const name = (item.activo === 'si') ? 'Desactivar' : 'Activar'
      this.$swal.fire({
        title: `Estas seguro de ${name} el registro ?`,
        // message: 'No podras revertir el movimiento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Si, ${name}`,
        cancelButtonText: 'No, Cerrar'
      }).then((result) => {
        if (result.value) {
          const url = this.get_urls['PUT/empresa/empleado/{id}']
          this.PUTDisableEnableObjectService({ url: url, params: item.id, replace: '{id}', data: {} })
        }
      })
    },
    updateData ({ item }) {
      this.objectMain = item
      this.paramsDialog = {
        form: this.get_EmpleadoForm,
        url: this.get_urls['POST/empresa/empleado'],
        message: 'Registro Actualizado Correctamente',
        setmodel: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'create', with: 850, title: 'Editar Empleado' })
    },
    deleteData  ({ item }) {
      this.$swal.fire({
        title: 'Estas seguro de eliminar el  registro ?',
        message: 'No podras revertir el movimiento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar !',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          const url = this.get_urls['DELETE/empresa/empleado/{id}']
          this.DELETEObjectService({ url: url, params: item.id, replace: '{id}' })
        }
      })
    },
    /* ========== ========== ========== ========== ========== */

    tableAction (item) {
      this[item.action](item)
    },
    toAssignRole (item) {
      this.form = this.get_assignRoles
      this.object = {
        id: item.item.id,
        usuario: item.item.usuario,
        NombreCompleto: item.item.empleado.NombreCompleto,
        roles: item.item.roles
      }
      this.url = this.get_urls['POST/config/auth/usuarios/assign-rol']
      this.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', with: 550, title: 'Asignar Rol - Usuario' })
    },
    toEditData (item) {
      this.user = item.item
      this.SHOW_DIALOG_MAIN({ type: 'update', with: 500, title: 'Editar Usuario' })
    },
    /*
    toUpdatePassword (item) {
      this.user = item.item
      this.SHOW_DIALOG_SECOUND_MAIN({ type: 'create', showtype: 'password' })
    },
    toMunicipiosAssign (item) {
      this.SET_DATA_SECOUND_MAIN(item.item)
      router.push({ name: 'ViewMunicipiosRoleAssign', params: { id: item.item.id } })
    },
    */
    ExecuteUrl (objet) {
      if (this.get_urls != null) {
        const router = this.get_urls[objet]
        this.POSTListObjectsService({ url: router, data: this.params })
      } else {
        this.GETUrlServices()
      }
    }
  },
  computed: {
    ...mapGetters(['get_urls', 'get_objects', 'get_dialogMain', 'get_EmpleadoForm', 'get_AssignRoles']),
    objectDetail() {
      if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0]
      }
      return {}
    }
  },
  watch: {
    get_urls () {
      this.ExecuteUrl('POST/empresa/empleado/params')
    },
    get_objects (rows) {
      if (rows) {
        this.table.modelMain = []
        if (rows.length > 0) this.table.modelMain.push(rows[0])
        this.table.header.loading = false
        this.table.body.data = rows
      }
    }
  },
  beforeDestroy () {
    this.CLEAR_LIST_DATA();
  }

}
</script>

<style>

</style>
crear curd de roles
