<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction" />
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :model="object" :params="paramsDialog" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DataTableMain from '@/components/tables/DataTableMain';
import MainFormDialog from '@/components/dialogs/MainFormDialog';
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [Permission],
  name: 'AlmacenesView',
  components: {
    DataTableMain,
    MainFormDialog,
  },
  data: () => ({
    table: {
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newData', code: 'configuracion.variables-sistema.create-update' },
        ],
        titles: [
          { text: 'Nombre', value: 'nombre', class: 'black--text' },
          { text: 'config', value: 'DinamicObject', class: 'black--text', fieldMain: 'config', type: 'json'},
          { text: 'Empresa', value: 'empresa.nombre', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: 'Fecha Creación', value: 'Created', class: 'black--text' },
          { text: '', value: 'ActionsGral', class: 'black--text', align: 'right' }
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [
          { title: 'Activar / Desactivar', icon: 'mdi-checkbox-blank-circle', color: 'grey darken-3', action: 'disableData', code: 'configuracion.variables-sistema.up-down' },
          { title: 'Editar', icon: 'mdi-pencil', color: 'amber darken-3', action: 'updateData', code: 'configuracion.variables-sistema.create-update' },
          { title: 'Eliminar', icon: 'mdi-delete', color: 'red darken-3', action: 'deleteData', code: 'configuracion.variables-sistema.delete' }
        ]
      },
      footer: {
        paging: true,
      },
    },
    paramsDialog: {},
  }),
  mounted() {
    this.refreshData({});
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'GETListObjectsService', 'PUTDisableEnableObjectService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    // *==========*==========*==========*==========* \\
    successAction(item) {
      this[item.action](item);
    },
    tableAction(item) {
      this[item.action](item);
    },
    refreshData(item) {
      this.table.header.loading = true;
      this.ExecuteUrl('GET/empresa/configuracion');
    },
    newData(item) {
      this.object = {};
      this.paramsDialog.form = this.get_ConfiguracionSistemaForm
      this.paramsDialog.url = this.get_urls['POST/empresa/configuracion']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', with: 850, title: 'Nueva Configuración del Sistema' })
    },

    disableData  ({ item }) {
      const name = (item.activo === 'si') ? 'Desactivar' : 'Activar'
      this.$swal.fire({
        title: `Estas seguro de ${name} el registro ?`,
        // message: 'No podras revertir el movimiento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Si, ${name}`,
        cancelButtonText: 'No, Cerrar'
      }).then((result) => {
        if (result.value) {
          const url = this.get_urls['PUT/empresa/configuracion/{id}']
          this.PUTDisableEnableObjectService({ url: url, params: item.id, replace: '{id}', data: {} })
        }
      })
    },
    updateData ({ item }) {
      this.object = item      
      this.paramsDialog = {
        form: this.get_ConfiguracionSistemaForm,
        url: this.get_urls['POST/empresa/configuracion'],
        message: 'Registro Actualizado Correctamente',
        setmodel: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'update', with: 850, title: 'Editar Configuración del Sistema' })
    },
    deleteData  ({ item }) {
      this.$swal.fire({
        title: 'Estas seguro de  el registro ?',
        message: 'No podras revertir el movimiento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar !',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          const url = this.get_urls['DELETE/empresa/configuracion/{id}']
          this.deleteServiceAction({ url: url, params: item.id, replace: '{id}' })
        }
      })
    },
    // *==========*==========*==========*==========* \\
    ExecuteUrl(url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url];
        this.GETListObjectsService({ url: router });
      } else {
        this.GETUrlServices();
      }
    },
  },
  computed: {
    ...mapGetters(['get_urls', 'get_objects', 'get_dialogMain',
      'get_ConfiguracionSistemaForm']),
  },
  watch: {
    get_urls() {
      this.refreshData({});
    },
    get_objects(data) {
      this.table.header.loading = false;
      this.table.body.data = data;
    },
  },
  beforeDestroy() {
    this.CLEAR_LIST_DATA();
  },
};
</script>

<style>

</style>
