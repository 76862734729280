<template>
    <v-row dense>
        <v-col cols="6" class="py-5">
            <v-toolbar color="green darken-1" dark>
                <span class="font-weight-bold text-h4 pr-2">$ {{intlRound(totalIncome)}} </span>
                <span class="font-weight-bold title">Ingresos</span>
            </v-toolbar>
            <DataTableMain :datatable="tableIngresos" :on-success="successAction" :items-action="tableAction" />
        </v-col>

        <v-col cols="6" class="py-5">
            <v-toolbar color="amber darken-2" dark>
                <span class="font-weight-bold text-h4 pr-2">$ {{intlRound(totalEgress)}}</span>
                <span class="font-weight-bold title">Egresos</span>
            </v-toolbar>
            <DataTableMain :datatable="tableEgresos" :on-success="successAction" :items-action="tableAction" />
        </v-col>
    </v-row>
</template>
<script>
import axios from 'axios'
import { HeaderGral } from '@/store/modules/config'
import { NumberUtils } from "@/mixins/NumberMixin"
import DataTableMain from '@/components/tables/DataTableMain'
export default {
  // name: 'ShowDetailCajaField',
  mixins: [NumberUtils],
  props: ['itemAction', 'setterModel', 'onSuccess'],
  data: () => ({
    objet: {},
    params: '',
    totalIncome:0.0,
    tableIngresos: {
      header: {
        options: [],
        titles: [
          { text: 'Folio Venta', value: 'folio', class: 'black--text' },
          { text: 'Fecha', value: 'date', class: 'black--text' },
          { text: 'Descripcion', value: 'description', class: 'black--text' },
          { text: 'Forma Pago', value: 'way_to_pay', class: 'black--text' },
          // { text: 'Monto', value: 'amount', class: 'black--text' }
          { text: "Monto", value: "DinamicImporte", class: "black--text", fieldMain: 'amount' }
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [],
      },
      footer: {
        paging: true,
      },
    },
    totalEgress:0.0,
    tableEgresos: {
      modelMain: [],
      header: {
        options: [],
        titles: [
          { text: 'Descripción', value: 'description', class: 'black--text' },
          { text: 'Fecha', value: 'date', class: 'black--text' },
          // { text: 'Monto', value: 'amount', class: 'black--text' },
          { text: "Monto", value: "DinamicImporte", class: "black--text", fieldMain: 'amount' }
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [],
      },
      footer: {
        paging: true,
      },
    }
  }),
  components: {
    DataTableMain
  },
  created() {
    if(this.setterModel){
      this.setParams()
      this.getDinamicData()
    }
  },
  methods: {
    DetallesCajas(detalles) {
      const income = detalles.filter( (item) => item.descripcion.descripcion && item.descripcion.status_id === 24 )
      const egress = detalles.filter( (item) => item.descripcion && item.descripcion.status_id === 25 )
      this.totalIncome = 0
        this.ingresosMain = income.map(item=>{
          this.totalIncome += (item.descripcion.pago)? item.descripcion.pago.importe : item.descripcion.monto
          return  {
                    id: item.id,
                    date: item.descripcion.fecha,
                    folio: item.descripcion.folio,
                    amount: (item.descripcion.pago)? item.descripcion.pago.importe : item.descripcion.monto,
                    user: item.descripcion.usuario.usuario,
                    description: item.descripcion.descripcion,
                    way_to_pay_id: (item.descripcion.forma_pago_id) ? item.descripcion.forma_pago.id : item.descripcion.pago.forma_pago.id,
                    way_to_pay: (item.descripcion.forma_pago_id) ? item.descripcion.forma_pago.nombre : item.descripcion.pago.forma_pago.nombre
                  }
        });
        this.tableIngresos.body.data = []
        this.tableIngresos.body.data.push(...this.ingresosMain)

        this.totalEgress = 0
        this.egresosMain = egress.map(item=>{
          this.totalEgress += item.descripcion.monto
          return  {
                    id: item.id,
                    amount: item.descripcion.monto,
                    description: item.descripcion.descripcion,
                    date: item.descripcion.fecha,
                    user: item.descripcion.usuario.usuario
                  }
        });
        this.tableEgresos.body.data = []
        this.tableEgresos.body.data.push(...this.egresosMain)
    },
    setParams() {
        this.params = '?'
        this.itemAction.fields.map(item=> {
            if(this.setterModel[item]) {
                if(this.params == '?') this.params +=`${item}=${this.setterModel[item]}`
                else this.params +=`&${item}=${this.setterModel[item]}`
            }
        })
    },
    successAction (item) {
      item.item = this.model
      this.onSuccess(item)
    },
    tableAction (item) {
      this.onSuccess(item)
    },
    getDinamicData () {
      const header = HeaderGral()
      this.tableIngresos.header.loading = true
      this.tableEgresos.header.loading = true
      const urlmain = `${this.itemAction.url}${this.params}`
      axios.get(`${urlmain}`, header).then((response) => {
        console.log('data', response.data)
        this.objet = {}
        this.objet = response.data
        if(response.data.last_movement) this.DetallesCajas(response.data.last_movement.detalles)
        this.tableIngresos.header.loading = false
        this.tableEgresos.header.loading = false
      }).catch((error) => {
        if (error == null) {
          console.log('Error')
        }
      })
    }
  }
}
</script>
  
<style scoped></style>
    