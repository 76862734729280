import Vue from 'vue'

export const HeaderGral = () => {
  const token = Vue.$cookies.get('token_pv')
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return headers
}
export const HeaderFileGral = () => {
  const token = Vue.$cookies.get('token_pv')
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      responseType: 'blob'
    }
  }
  return headers
}
export const HeaderFileBoundaryGral = (boundary) => {
  const token = Vue.$cookies.get('token_pv')
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data' + boundary
    },
  }
  return headers
}
export const UserSession = () => {
  const user = Vue.$cookies.get('user_pv')
  return user
}
export const Token = () => {
  const token = Vue.$cookies.get('token_pv')
  return token
}
