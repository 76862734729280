  <template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction" />
      </v-col>
    </v-row>
    <MainFormDialog
      v-if="get_dialogMain.dialog"
      :model="object"
      :params="paramsDialog"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DataTableMain from '@/components/tables/DataTableMain';
import MainFormDialog from '@/components/dialogs/MainFormDialog';
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [Permission],
  name: 'CaracteristicasView',
  components: {
    DataTableMain,
    MainFormDialog,
  },
  data: () => ({
    table: {
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newData', code: 'catalogo.caracteristicas.create-update' },
        ],
        titles: [
          { text: 'Nombre', value: 'nombre', class: 'black--text' },
          { text: 'Tipo Dato', value: 'tipo_dato.nombre', class: 'black--text' },
          { text: 'Configuracion', value: 'tipo_dato.default', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          // { text: 'Created', value: 'Created', class: 'black--text' },
          // { text: 'Updated', value: 'Updated', class: 'black--text' },
          { text: '', value: 'ActionsGral', class: 'black--text', align: 'right' }
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [
          { title: 'Activar / Desactivar', icon: 'mdi-checkbox-blank-circle', color: 'grey darken-3', action: 'disableData', code: 'catalogo.caracteristicas.up-down' },
          { title: 'Editar', icon: 'mdi-pencil', color: 'amber darken-3', action: 'updateData', code: 'catalogo.caracteristicas.create-update' },
          { title: 'Eliminar', icon: 'mdi-delete', color: 'red darken-3', action: 'deleteData', code: 'catalogo.caracteristicas.delete' }
        ],
      },
      footer: {
        paging: true,
      },
    },
    paramsDialog: {},
  }),
  mounted() {
    this.ExecuteUrl('GET/catalogo/caracteristicas');
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'GETListObjectsService','PUTDisableEnableObjectService','DELETEObjectService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    // *==========*==========*==========*==========* \\
    successAction(item) {
      this[item.action](item);
    },
    tableAction(item) {
      this[item.action](item);
    },
    refreshData(item) {
      this.table.header.loading = true;
      this.ExecuteUrl('GET/catalogo/caracteristicas');
    },
    newData(item) {
      this.object = {};
      this.paramsDialog = {
        form: this.get_CaracteristicasForm,
        url: this.get_urls['POST/catalogo/caracteristicas'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({ type: 'create', with: 850, title: 'Nuevo Caracteristica' });
    },

    disableData  ({ item }) {
      const name = (item.activo === 'si') ? 'Desactivar' : 'Activar'
      this.$swal.fire({
        title: `Estas seguro de ${name} el registro ?`,
        // message: 'No podras revertir el movimiento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Si, ${name}`,
        cancelButtonText: 'No, Cerrar'
      }).then((result) => {
        if (result.value) {
          const url = this.get_urls['PUT/catalogo/caracteristicas/{id}']
          this.PUTDisableEnableObjectService({ url: url, params: item.id, replace: '{id}', data: {} })
        }
      })
    },
    updateData ({ item }) {
      this.object = item
      this.paramsDialog = {
        form: this.get_CaracteristicasForm,
        url: this.get_urls['POST/catalogo/caracteristicas'],
        message: 'Registro Agregado Correctamente',
        setmodel: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'create', with: 850, title: 'Nuevo Cliente' })
    },
    deleteData  ({ item }) {
      this.$swal.fire({
        title: 'Estas seguro de  el registro ?',
        message: 'No podras revertir el movimiento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar !',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          const url = this.get_urls['DELETE/catalogo/caracteristicas/{id}']
          this.DELETEObjectService({ url: url, params: item.id, replace: '{id}' })
        }
      })
    },
    // *==========*==========*==========*==========* \\
    ExecuteUrl(url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url];
        this.GETListObjectsService({ url: router, data: {} });
      } else {
        this.GETUrlServices();
      }
    },
  },
  computed: {
    ...mapGetters(['get_urls', 'get_objects', 'get_dialogMain',
      'get_CaracteristicasForm']),
  },
  watch: {
    get_urls() {
      this.ExecuteUrl('GET/catalogo/caracteristicas');
    },
    get_objects(data) {
      this.table.header.loading = false;
      this.table.body.data = data;
    },
  },
  beforeDestroy() {
    this.CLEAR_LIST_DATA();
  },
};
</script>

<style>

</style>
