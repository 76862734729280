<template>
  <v-card tile width="auto" class="py-4">
    <v-card-text>
      <v-form ref="form">
        <v-row dense>
          <v-col v-for="(field, i) in form.status_finish.formulario.fields" :key="i"
            :cols="field.cols" :offset="field.offset" :hidden="field.hidden">

            <div v-if="field.field == 'viewText' && !field.hidden" :class="field.classMain">
              <div v-if="field.typeString && field.typeString === 'number' ">
                <p class="font-weight-bold black--text" >{{field.name}}</p>  
                <p>$ {{intlRound(model[field.nameid])}}</p>
              </div>
              <div v-else-if="field.typeString && field.typeString === 'number_refresh' ">
                <p class="font-weight-bold black--text" >{{field.name}}</p>
                <p>$ {{intlRound(importe_total)}}</p>
              </div>
              <div v-else>
                <label class="font-weight-bold">{{ field.name }}: </label>
                <span>{{ model[field.nameid] }}</span>
              </div>
            </div>
            <p v-if="field.field == 'messageViewText' && !field.hidden" :class="field.classMain">
              <label class="black--text font-weight-bold">{{ field.name }} </label>
            </p>

            <v-text-field
              v-if="field.field == 'text' && !field.hidden"
              v-model="model[field.nameid]"
              :label="field.name"
              :type="field.type"
              :rules="(field.rules)? fieldRequired : []"
              :readonly="field.readonly"
              outlined
              dense
            />
            <v-text-field
              v-if="field.field == 'number' && !field.hidden"
              v-model.number="model[field.nameid]"
              :rules="field.rules ? fieldRequired : []"
              :readonly="field.readonly"
              :label="field.name"
              :type="field.type"
              @keyup="actionDinamicData(field)"
              outlined
              min="0"
              dense
            />

            <v-textarea
              v-else-if="field.field == 'textArea' && !field.hidden"
              v-model="model[field.nameid]"
              :label="field.name"
              :name="field.dimens"
              :rules="(field.rules)? fieldRequired : []"
              outlined
              dense
            />

            <v-select
              v-else-if="field.field == 'select' && !field.hidden"
              v-model="model[field.nameid]"
              :label="field.name"
              :items="field.items"
              :rules="(field.rules)? fieldRequired : []"
              outlined
              dense
            />

            <div v-else-if="field.field == 'radioButton' && !field.hidden">
              <label class="black--text font-weight-bold font-weight-black subtitle-2">{{ field.name }}</label>
              <v-radio-group
                v-model="model[field.nameid]"
                :row="field.row"
                :rules="(field.rules)? fieldRequired : []"
                class="pa-1 ma-1"
                @change="RadioButtonEvent(field)"
              >
                <v-radio
                  v-for="(value,index) in field.items"
                  :key="index"
                  :label="(value.name)? value.name : value"
                  :value="(value.name)? value.value : value"
                />
              </v-radio-group>
            </div>

            <div v-else-if="field.field == 'checkbox'">
              <p class="ma-0 pa-0 text--black font-weight-bold font-weight-black subtitle-2">
                {{ field.name }}
              </p>
              <v-checkbox
                v-for="value in field.items"
                :key="value"
                v-model="model[field.nameid]"
                :class="(field.row)? 'mr-3 pa-0 checkbook-horizontal' : 'ma-0 pa-0'"
                :label="value"
                :value="value"
                :rules="(field.rules)? fieldRequired : []"
              />
            </div>

            <SelectedDinamicField
              v-else-if="field.field == 'selectDataServer' && !field.hidden"
              :setter-model="model[field.nameid]"
              :item-action="field"
              :on-success="actionFieldData"
            />

            <SearchDinamicField
              v-else-if="field.field == 'searchDataServer' && !field.hidden"
              :setter-model="model"
              :item-action="field"
              :on-success="actionFieldData"
            />

            <SelectedGroupDinamicField v-else-if="field.field == 'selectGroupDataServer' && !field.hidden"
                :itemAction="field" :on-success="actionFieldData"/>

            <FileInputDinamicField v-else-if="field.field == 'fileInputDataServer' && !field.hidden"
              :itemAction="field"
              :on-success="actionFieldData"
            />
            <ScrollVirtualField v-else-if="field.field == 'scrollVirtualDataServer' && !field.hidden"
              :setterModel="model[field.nameid]"
              :itemAction="field"
              :on-success="actionFieldData"
            />
            <ScrollApplyInvetoryField v-else-if="field.field == 'scrollVirtualApplyInvetoryDataServer' && !field.hidden"
              :setterModel="model[field.nameid]"
              :itemAction="field"
              :on-success="actionFieldData"
            />
            <ShowPriceCajaField v-else-if="field.field == 'showFormaPagosField' && !field.hidden"
              :setterModel="model[field.nameid]"
              :itemAction="field"
              :on-success="actionFieldData"
            />
          <!--
          <DatePicker v-else-if="field.field == 'textDatePicker' && !field.hidden"
            :setterModel="model[field.nameid]"
            :itemAction="field"
            :on-success="actionFieldData"/>
          <SelectedMultipleDinamicField v-else-if="field.field == 'selectMultipleDataServer' && !field.hidden"
                :itemAction="field" :on-success="actionFieldData"/>
          -->


          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-layout justify-end>
        <v-btn
          v-if="onCancel"
          class="mr-3"
          color="red darken-1"
          depressed
          outlined
          @click="onCancel"
        >
          Cerrar
        </v-btn>
        <v-btn
          class="mr-3"
          color="success darken-1"
          depressed
          outlined
          :loading="btnloading"
          :disabled="btnloading"
          @click="sendForm"
        >
          {{ form.status_finish.nombre }}
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Validate } from '@/mixins/validateFormMixin'
import { NumberUtils } from '@/mixins/NumberMixin'
// import DatePicker from '@/components/fields/DatePicker'
import SearchDinamicField from '@/components/fields/SearchDinamicField'
import SelectedDinamicField from '@/components/fields/SelectedDinamicField'
import ScrollVirtualField from '@/components/fields/specific/ScrollVirtualField'
import ScrollApplyInvetoryField from '@/components/fields/specific/ScrollApplyInvetoryField'
import SelectedGroupDinamicField from '@/components/fields/SelectedGroupDinamicField'
// import SelectedMultipleDinamicField from '@/components/fields/SelectedMultipleDinamicField'
import FileInputDinamicField from '@/components/fields/FileInputDinamicField'
import ShowPriceCajaField from '@/components/fields/specific/ShowPriceCajaField'

export default {
  name: 'MainProcessForm',
  components: {
    SelectedDinamicField,
    SearchDinamicField,
    ScrollVirtualField,
    ScrollApplyInvetoryField,
    SelectedGroupDinamicField,
    FileInputDinamicField,
    ShowPriceCajaField
    /*
    DatePicker,
    SelectedMultipleDinamicField
    */
  },
  mixins: [Validate, NumberUtils],
  props: ['form', 'model', 'onSuccess', 'onCancel'],
  data: () => ({
    selected: [],
    importe_total: 0,
    btnloading: false
  }),
  methods: {
    ...mapActions([]),
    ...mapMutations(['SHOW_LOADING_MAIN', 'HIDDE_LOADING_MAIN']),
    RadioButtonEvent(item) {
      if (item.action) {
        console.log(item)
        this[item.action]({ fieldMain: item })
      }
    },
    ShowFieldDinamic({ fieldMain }) {
      const response = this.model[fieldMain.nameid]
      if (fieldMain.isHidden) {
        fieldMain.isHidden.map((item, i) => {
          this.form.status_finish.formulario.fields.map((form, index) => {
            if (item === form.nameid) {
              if (item === 'almacen_id' && response) {
                form.url = `${form.url_default}/${response}`
                form.hidden = false
                this.model[form.nameid] = null
                return
              } else if (response) {
                form.hidden = false
                this.model[form.nameid] = ''
                return
              }
              form.hidden = true
              this.model[form.nameid] = null
            }
          })
        })
      }
    },
    actionFieldData(obj) {
      this.model[obj.id] = (obj.data === undefined) ? null : obj.data
      this.actionDinamicData(obj)
    },
    actionDinamicData(obj) {
      if (obj.action) {
        this[obj.action](obj)
      } else if (obj.fieldMain && obj.fieldMain.action) {
        this[obj.fieldMain.action](obj)
      }
    },
    
    findBillEmployee({ id, data, field }) {
      if (field.isHidden) {
        field.isHidden.map((item, i) => {
          this.form.status_finish.formulario.fields.map((form, index) => {
            if (item === form.nameid) {
              if (form.hidden) {
                form.params[id] = data
                form.hidden = false
                return
              }
              form.params = {}
              form.hidden = true
              this.model[form.nameid] = null
            }
          })
        })
      }
    },
    calculateImport (field) {
      let importe = 0
      this.form.status_finish.formulario.fields.map((f, i) => {
        if(f.calculate) {
          if(this.model[f.nameid]) {
            const c =  this.model[f.nameid]
            importe += (c * f.value)
          }
        }
      })
      this.importe_total = importe
      this.model[field.isHidden] = this.intlRound(importe)
    },
    calculateCambio (field) {
      this.model[field.nameid] = this.roundDecimal(this.model[field.nameid])
      this.model[field.isHidden] = this.roundDecimal(this.model[field.isHidden])

      let pago = this.model[field.nameid] - this.model[field.isHidden]
      if (pago < 0) {
        this.model.cambio = this.roundDecimal( 0 )
        this.importe_total = this.roundDecimal( 0 )
      } else{
        this.model.cambio = this.roundDecimal( pago )
        this.importe_total = this.roundDecimal( pago )
      }
    },
    ShowFieldDinamicMultiple ({ nameObject, objectMain, fieldMain }) {
      if (fieldMain.isHidden) {
        fieldMain.isHidden.map(item => {
          this.form.status_finish.formulario.fields.map((form, index) => {
            if (item === form.nameid) {
             if (objectMain.nombre && (objectMain.nombre === 'TARJETA DEBITO' || objectMain.nombre === 'TARJETA CREDITO')) {
                form.hidden = false
                this.model[form.nameid] = ''
                return
             }
              form.hidden = true
              this.model[form.nameid] = null
            }
          })
        })

        fieldMain.isshow.map(item => {
          this.form.status_finish.formulario.fields.map((form, index) => {
            if (item === form.nameid) {
              if (objectMain.nombre && (objectMain.nombre === 'TARJETA DEBITO' || objectMain.nombre === 'TARJETA CREDITO')) {
                // console.log(fieldMain.fieldshow)
                // console.log('fieldMain.fieldshow', this.model[fieldMain.fieldshow])
                  form.hidden = true
                  this.model[form.nameid] = this.model[fieldMain.fieldshow]
                  return
              }
              form.hidden = false
              this.model[form.nameid] = 0
            }
          })
        })
      }
      fieldMain.nameid
      objectMain.nombre
      const response = this.model[fieldMain.nameid]
    },

    sendForm() {
      if (this.$refs.form.validate()) {
        this.SHOW_LOADING_MAIN(true)
        this.onSuccess({
          descripcion: this.model,
          action: this.form.accion,
          status: this.form.estatus_final_id,
          actual: this.form.estatus_actual_id,
        })
      }
    },
  },
  computed: {
    ...mapGetters(['get_shoeBtnLoading']),
  },
  watch: {
    get_shoeBtnLoading (val) {
      this.btnloading = val
    }
  },
}
</script>

<style>
.box-color-red {
  border: 1px red solid
}
div .checkbook-horizontal{
  display: inline-block
}
</style>