<template>
  <div>
    <v-toolbar flat color="white" class="elevation-2" >
      <v-toolbar-items class="hidden-sm-and-down pt-4">
        <span v-for="(item, index) in datatable.header.options" :key="index">
          <span v-if="item.hidden == ''">
            <v-btn v-if="item.action" small :color="item.color" dark class='mr-1' @click="action(item)" >
                {{ item.title }}
                <v-icon right dark v-if="item.icon">{{ item.icon }}</v-icon>
            </v-btn>
            <v-menu v-else offset-y>
                <template v-slot:activator="{ attrs, on }">
                    <v-btn color="teal darken-1" class="white--text" v-bind="attrs" v-on="on" small>
                        {{item.title}} <v-icon right dark>{{item.icon}}</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item v-for="(option, index) in item.options" :key="index" @click="action(option)" link dense>
                        <v-list-item-icon>
                          <v-icon>{{option.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle>{{ option.title }}</v-list-item-subtitle>
                    </v-list-item>
                </v-list>
            </v-menu>
          </span>
        </span>
      </v-toolbar-items>
      <span class="hidden-md-and-up">
        <v-menu>
            <template v-slot:activator="{ attrs, on }">
              <v-app-bar-nav-icon v-bind="attrs" v-on="on" small></v-app-bar-nav-icon>
            </template>
            <v-list dense>
              <span v-for="(item, index) in datatable.header.options" :key="index">
                <span v-if="item.hidden == ''">
                  <v-list-item v-if="item.action" @click="action(item)" link dense>
                      <v-list-item-icon>
                        <v-icon>{{item.icon}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-group v-else :value="true" no-action sub-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>{{item.title}} <v-icon right dark>{{item.icon}}</v-icon></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item v-for="(option, index) in item.options" :key="index" @click="action(option)" link dense>
                        <v-list-item-icon>
                          <v-icon>{{option.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle>{{ option.title }}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list-group>
                </span>
              </span>
            </v-list>
        </v-menu>
      </span>
      <v-spacer></v-spacer>
      <v-text-field v-model="buscar" label="Buscar" class="inputSearch"  @keyup="searchData" clearable outlined dense>
          <!-- @keyup="searchData" -->
          <!-- @click:clear="clearDataSerarch()" -->
      </v-text-field>
    </v-toolbar>

    <v-data-table
        v-model="datatable.modelMain"
        :item-key="datatable.itemKey"
        :show-select="datatable.showSelect"
        :single-select="datatable.singleSelect"
        :headers="datatable.header.titles"
        :items="datatable.body.data"
        :options.sync="pagination"
        :update:pagination="pagination"
        :server-items-length="datatable.body.totalData"
        :hide-default-footer="!datatable.footer.paging"
        :footer-props="footer"
        :loading="datatable.header.loading"
        loading-text="Cargando por favor espere"
        class="elevation-1"
        dense>
        <template #no-data>
          <v-alert :value="true" color="info" class="ma-1" border="left" type="info" icon="warning" dense>
            Lo siento, no hay registros para mostrar :(
          </v-alert>
        </template>
        <template #footer.page-text="props">
          {{props.pageStart}} - {{props.pageStop}} de {{props.itemsLength}}
        </template>

        <template #item.Status="{ item }">
          <v-chip v-if="item.last_movement"
            class="font-weight-bold text-uppercase" :color="item.last_movement.estatus.color" outlined label small>
            {{ item.last_movement.estatus.estatus }}
          </v-chip>
        </template>
        <template #item.Activo="{ item }">
          <v-chip class="ma-1" :color="(item.activo == 'si')? 'success':'error'" outlined small label>
            <span class="font-weight-bold text-uppercase">{{ item.activo }}</span>
          </v-chip>
        </template>
        <template #item.EstatusFacturado="{ item }">
          <v-chip class="font-weight-bold text-uppercase" color="info" outlined label small>
            <span v-if="item.inventarios.facturado"> SI </span>
            <span v-else> NO </span>
          </v-chip>
        </template>

        <template #item.Actions="{ item }">
          <span>
            <v-menu offset-y>
              <template #activator="{ attrs, on }">
                <v-btn
                  color="teal darken-1"
                  class="white--text ma-1"
                  v-bind="attrs"
                  small
                  v-on="on">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <!-- Acciones procesos -->
                <v-list-item-group>
                  <span v-if="item.last_movement && item.last_movement.actions.length > 0">
                    <v-list-item
                      v-for="(action, index) in item.last_movement.actions"
                      :key="index"
                      link
                      @click="items({action:action.accion, item:item, body:action})"
                    >
                      <v-list-item-icon>
                        <v-icon :color="action.status_finish.color">mdi-decagram</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ action.status_finish.nombre }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </span>
                </v-list-item-group>

                <!-- Divisor -->
                <v-list-item dense>
                  <v-divider />
                  <v-subheader class="black--text font-weight-bold text-center">Generales</v-subheader>
                  <v-divider />
                </v-list-item>

                <!-- Acciones Locales -->
                <v-list-item-group>
                  <span
                    v-for="(event, index) in datatable.body.actions"
                    :key="index"
                  >
                    <v-list-item
                      v-if="event.hidden == ''"
                      link
                      @click="items({action:event.action, item:item, body:action})"
                    >
                      <v-list-item-icon>
                        <v-icon :color="event.color">{{ event.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title> {{ event.title }} </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </span>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </span>
        </template>
        <template #item.ActionsGral="{ item }">
          <span>
            <v-menu offset-y>
              <template #activator="{ attrs, on }">
                <v-btn
                  color="teal darken-1"
                  class="white--text ma-1"
                  v-bind="attrs"
                  small
                  v-on="on">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <!-- Acciones Locales -->
                <v-list-item-group>
                  <span v-for="(event, index) in datatable.body.actions" :key="index">
                    <v-list-item v-if="event.hidden == ''" link @click="items({action:event.action, item:item, body:action})">
                      <v-list-item-icon>
                        <v-icon :color="event.color">{{ event.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold black--text subtitle-2"> {{ event.title }} </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </span>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </span>
        </template>

        <template #item.Created="{ item }">
          {{ convertDate(item.created_at) }}
        </template>
        <template #item.Updated="{ item }">
          {{ convertDate(item.updated_at) }}
        </template>
    </v-data-table>
  </div>
</template>

<script>
import { Validate } from '@/mixins/validateFormMixin';
export default {
  name: 'TableMain',
  mixins: [Validate],
  props: ['datatable', 'onSuccess', 'itemsAction', 'onPagination', 'onSearch'],
  data: () => ({
    expand: false,
    buscar: '',
    footer: {
      showFirstLastPage: true,
      'items-per-page-text': 'Registros por página'
    },
    pagination: {},
    timeout: null
    // excluir: ['Shift', 'Control', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']
  }),
  methods: {
    action (item) {
      if (item.pagination) {
        item.pagination = this.pagination
      }
      this.onSuccess(item)
    },
    items (item) {
      this.itemsAction(item)
    },
    convertDate (date) {
      const dateString = new Date(date).toISOString().substr(0, 10)
      const timeString = new Date(date).toLocaleTimeString()

      return `${dateString} ${timeString}`
    },
    searchData (val) {
      const exist = this.excluir.find(key => key === val.key)
      if (exist) return
      clearTimeout(this.timeout);
      var self = this
      this.timeout = setTimeout(function () {
        clearTimeout(self.  timeout);
        if (self.buscar === '') {
          self.onPagination(self.pagination)
          return
        } else {
          self.onSearch({
            itemsPerPage: val.itemsPerPage,
            page: val.page,
            search: self.buscar,
            sortDesc: self.pagination.sortDesc,
            sortBy: self.pagination.sortBy
          })
        }
      }, 500);
    },
    clearDataSerarch () {
      this.buscar = ''
      this.onPagination(this.pagination)
    }
  },
  watch: {
    pagination (val) {
      if (this.buscar) {
        this.onSearch({
          itemsPerPage: val.itemsPerPage,
          page: val.page,
          search: this.buscar,
          sortDesc: this.pagination.sortDesc,
          sortBy: this.pagination.sortBy
        })
      } else {
        this.onPagination(val)
      }
    },
    buscar1 (val) {
      // console.log(this.pagination)
      if (val === null || val === '') {
        this.onPagination(val)
        return
      }
      this.onSearch({
        itemsPerPage: this.pagination.itemsPerPage,
        page: this.pagination.page,
        search: val,
        sortDesc: this.pagination.sortDesc,
        sortBy: this.pagination.sortBy
      }) // .replace(' ', '_')
    }
  }
}
</script>

<style>
.inputSearch{
  padding-top: 25px !important;
}
</style>
