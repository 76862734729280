<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card tile>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="4" md="3">
                <DatePicker :item-action="fieldDateInit" :on-success="actionFieldData" />
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <DatePicker :item-action="fieldDateFinal" :on-success="actionFieldData" />
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <v-btn color="success" @click="searchSolicitudes" dark>
                  <v-icon left dark>mdi-calendar-search</v-icon>
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction" />
      </v-col>
      <v-col cols="12">
        <ViewDetailsMain
          class="my-2"
          :object="objectDetail"
          type="ComprasDetails"
          :items-action="tableAction"
        />
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :model="object" :params="paramsDialog" :on-success="dataForm"/>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import MainFormDialog from '@/components/dialogs/MainFormDialog'
import DatePicker from '@/components/fields/DatePicker'
import ViewDetailsMain from '@/components/details/ViewDetailsMain.view'
import { DatesUtils } from '@/mixins/datesUtilsMixin'
import { Permission } from '@/mixins/permissionMain'
import { UserSession } from '@/store/modules/config'

export default {
  name: 'InvComprasView',
  components: {
    DataTableMain,
    MainFormDialog,
    DatePicker,
    ViewDetailsMain
  },
  mixins: [DatesUtils, Permission],
  data: () => ({
    table: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newData', code: 'compra.options.toCreate' },
          {
            title: 'Reportes',
            icon: 'mdi-cloud-download',
            color: 'light-blue lighten-1',
            code: 'compra.reporte',
            options: [
              { title: 'Reporte de Facturado / No Facturado', icon: 'mdi-clipboard-text', color: 'yellow darken-3', action: 'ExportBills', code: 'compra.reporte.facturados-nofacturados-excel' },
              { title: 'Reporte General de Compras', icon: 'mdi-clipboard-text', color: 'yellow darken-3', action: 'ExportSaleGral', code: 'compra.reporte.compra-generales-excel' },
            ]
          }
        ],
        titles: [
          { text: 'Folio', value: 'folio', class: 'black--text' },
          { text: 'Tipo Solicitud', value: 'tipo_solicitud.nombre', class: 'black--text' },
          { text: 'Fecha Solicitud', value: 'fecha', class: 'black--text' },
          { text: 'Proveedor', value: 'log.proveedor.nombre', class: 'black--text' },
          { text: 'SubTotal', value: 'DinamicImporte', fieldMain: 'subtotal', class: 'black--text' },
          { text: 'Impuestos', value: 'DinamicImporte', fieldMain: 'total_impuesto', class: 'black--text' },
          { text: 'Total', value: 'DinamicImporte', fieldMain: 'total', class: 'black--text' },
          { text: 'Facturado', value: 'Facturado', fieldMain: 'total', class: 'black--text' },

          { text: 'Estatus', value: 'Status', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: '', value: 'Actions', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: [
          { title: 'Eliminar', icon: 'mdi-delete', color: 'error', action: 'deleteData', code: 'compra.delete' }
        ]
      },
      footer: {
        paging: true
      }
    },
    tablePrudcto: {
      header: {
        options: [],
        titles: [
          // { text: 'Articulo', value: 'articulo.id', class: 'black--text' },
          { text: 'Producto', value: 'articulo.caracteristicas.descripcion', class: 'black--text' },
          { text: 'Cantidad', value: 'cantidad', class: 'black--text' },
          { text: 'Unidad', value: 'articulo.udm.nombre', class: 'black--text' },
          { text: 'Precio Unitario', value: 'precio_unitario', class: 'black--text' },
          { text: 'Subtotal', value: 'DinamicImporte', fieldMain: 'subtotal', class: 'black--text' },
          { text: 'Impuesto', value: 'DinamicImporte', fieldMain: 'impuesto', class: 'black--text' },
          { text: 'Importe', value: 'DinamicImporte', fieldMain: 'importe', class: 'black--text' },
          { text: '', value: 'ActionsGral', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: [
          { title: 'Eliminar', icon: 'mdi-delete', color: 'error', action: 'deleteData', code: 'libre', hidden: '' }
        ]
      },
      footer: {
        paging: true
      }
    },
    showFieldDate: false,
    fieldDateInit: { field: 'textDatePicker', name: 'Fecha Inicio', nameid: 'fecha_init', default: true, cols: 3 },
    fieldDateFinal: { field: 'textDatePicker', name: 'Fecha Final', nameid: 'fecha_final', default: true, cols: 3 },
    paramsDialog: {},
    object: {},
    params: {},
    caja: {}
  }),
  computed: {
    ...mapGetters(['get_urls', 'get_dialogMain', 'get_objects', 'get_object', 'get_objectDelete',
      'get_SearchProductsForm', 'get_UpdateProductForm', 'get_SearchComprasForm', 'get_RangeDateTimeForm']),
    objectDetail () {
      if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0]
      }
      return {}
    }
  },
  watch: {
    get_urls (val) { this.ExecuteUrl('POST/compra/solicitudes') },
    get_object (val) {
      if (val.message) {
        this.$swal({
          type: 'error',
          icon: 'error',
          title: 'Error !',
          text: val.message,
        })
        return
      }
      this.caja = val
      this.params.caja = val.id
      this.showFieldDate = true
    },
    get_objects (rows) {
      if (rows) {
        this.table.modelMain = []
        if (rows.length > 0) {
          this.table.modelMain.push(rows[0])
        }
        this.table.body.data = rows
        this.table.header.loading = false
      }
    },
    get_objectDelete (data) {
      if (data.message) {
        this.$swal({ type: 'error', icon: 'error', title: 'Oops...!', text: data.message })
        return
      }
      this.refreshData({})
    }
  },
  mounted () {
    const date = new Date()
    const fecha = this.formatoFecha(date, 'yyyy-mm-dd')
    this.params.fecha_init = fecha
    this.params.fecha_final = fecha
    this.ExecuteUrl('POST/compra/solicitudes')
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'POSTListObjectsService', 'GETObjectService', 'DELETEObjectTwoService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    dataForm ({ name, model }) {
      if (name === 'recepcion') {
        let index = -1
        this.table.body.data.map((row, i) => { if (row.id === model.id) index = i })
        if (index !== -1) Object.assign(this.table.body.data[index], model)
        this.table.header.loading = false
      } else {
        this.refreshData({})
      }
    },
    // *==========*==========*==========*==========* \\
    searchSolicitudes () {
      this.table.header.loading = true
      this.ExecuteUrl('POST/compra/solicitudes')
    },
    actionFieldData (obj) {
      this.params[obj.id] = obj.data === undefined ? null : obj.data
    },
    successAction (item) {
      this[item.action](item)
    },
    refreshData (item) {
      this.table.header.loading = true
      this.ExecuteUrl('POST/compra/solicitudes')
    },
    newData (item) {
      // tipo_impuesto_id: 2,
      this.object = { precio_unitario: 0, almacen_id: UserSession().almacen_id }
      this.tablePrudcto.body.data = []
      this.paramsDialog = {
        form: this.get_SearchProductsForm,
        urlprocess: this.get_urls['GET/compra/options[/{status_id}]'],
        url: this.get_urls['POST/compra/execute'],
        formDetail: true,
        tableData: this.tablePrudcto,
        setmodel: true,
        message: 'Registro Agregado Correctamente'
      }
      this.SHOW_DIALOG_MAIN({ type: 'create', with: '70%', title: 'Nueva Solicitud' })
    },
    tableAction (item) {
      this[item.action](item)
    },
    toReview (item) {
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/compra/execute'],
        message: 'Registro Agregado Correctamente'
      }
      this.SHOW_DIALOG_MAIN({
        type: 'revision',
        form: 'process',
        with: 650,
        title: 'FINALIZAR LA REVISION'
      })
    },
    toApprove (item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/compra/execute'],
        message: 'Registro Agregado Correctamente'
      }
      this.SHOW_DIALOG_MAIN({
        type: 'create',
        form: 'process',
        with: 650,
        title: 'APROVAR LA ORDEN DE COMPRA'
      })
    },
    toPurchase (item) {
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/compra/execute'],
        message: 'Registro Agregado Correctamente'
      }
      this.SHOW_DIALOG_MAIN({
        type: 'comprar',
        form: 'process',
        with: 650,
        title: 'REALIZAR COMPRA'
      })
    },
    toReceive (item) {
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/compra/execute'],
        message: 'Registro Agregado Correctamente',
        data: { productos: item.item.detalles },
        setmodel: true,
        returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'recepcion', form: 'process', with: '60%', title: 'RECEPCIONAR COMPRA' })
    },
    toCheckIn (item) {
      // console.log('item', item)
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/compra/execute'],
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        data: { confirmar: true }
      }
      this.SHOW_DIALOG_MAIN({
        type: 'cerrar',
        form: 'process',
        with: 650,
        title: 'CERRAR COMPRA',
      })
    },
    toCheckInPartial (item) {
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/compra/execute'],
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        data: { confirmar: true }
      }
      this.SHOW_DIALOG_MAIN({
        type: 'cerre-parcial',
        form: 'process',
        with: 650,
        title: 'RECEPCIONAR COMPRA PARCIAL'
      })
    },
    toBill (item) {},
    toCancel (item) {},
    deleteData ({ item }) {
      this.$swal.fire({
        title: 'Estas seguro de  el registro ?',
        message: 'No podras revertir el movimiento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar !',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          const url = this.get_urls['DELETE/compra/solicitudes/{id}']
          this.DELETEObjectTwoService({ url: url, params: item.id, replace: '{id}' })
        }
      })
    },
    UpdateDetailData ({ item }) {
      const solicitud = this.table.body.data.find(i => i.id === item.solicitud_id)
      if (solicitud) {
        if (solicitud.last_movement.estatus_id === 9) {
          this.$swal({ type: 'warning', icon: 'warning', title: 'Advertencia !', text: 'El articulo no puede ser editado, la orden esta cerrado.' })
          return
        }
      } else {
        this.$swal({ type: 'warning', icon: 'warning', title: 'Advertencia !', text: 'La orden no fue encontrado.' })
        return
      }

      this.object = item
      this.object.prices = item.configuracion.precios
      this.paramsDialog = {
        form: this.get_UpdateProductForm,
        url: this.get_urls['POST/compra/detalles'],
        setmodel: true,
        message: 'Registro Agregado Correctamente',
        returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'updateDetail', with: '70%', title: 'Editar Articulo' })
    },
    deleteDetailData ({ item }) {
      this.$swal.fire({
        title: 'Estas seguro de  el registro ?',
        message: 'No podras revertir el movimiento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar !',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          const url = this.get_urls['DELETE/compra/detalles/{id}']
          this.DELETEObjectTwoService({ url: url, params: item.id, replace: '{id}' })
        }
      })
    },
    // *==========*==========*==========*==========* \\
    ExportBills (item) {
      const urlFac = this.get_urls['POST/compra/reportes/facturados-nofacturados']
      const form = this.get_SearchComprasForm
      this.object = { tipo_reporte: 'excel', sucursal_id: this.params.sucursal_id, nombre: 'ProductosCompras' }
      this.paramsDialog = {
        form: form,
        url: urlFac,
        message: 'Registros no Encontrados',
        exportData: true,
        setmodel: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'reportebills', with: 900, title: 'REPORTE DE PRODUCTOS', body: { isbutton: false } })
    },
    ExportSaleGral (item) {
      this.object = { tipo_reporte: 'excel', sucursal_id: this.params.sucursal_id, nombre: 'ComprasGenerales' }
      const form = this.get_RangeDateTimeForm
      form.forEach(f => {
        if (f.nameid === 'ruta') {
          f.route = '/compra/rpt/general/pdf'
        }
      })
      this.paramsDialog = {
        form: form,
        url: this.get_urls['POST/compra/reportes/compras-generales'],
        message: 'Registros no Encontrados',
        exportData: true,
        setmodel: true
        // returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'reportesalegral', with: 900, title: 'REPORTE GENERAL VENTAS' })
    },
    // *==========*==========*==========*==========* \\
    // *==========*==========*==========*==========* \\
    ExecuteUrl (url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url]
        this.POSTListObjectsService({ url: router, data: this.params })
      } else {
        this.GETUrlServices()
      }
    }
  }
}
</script>
<style></style>
