<template>
  <v-card
    v-if="model.id"
    class="elevation-2"
    tile
  >
    <v-card-text>
      <v-row dense>
        <v-col cols="6">
          <div class="title">
            <p class="black--text font-weight-bold ma-0">
              Cliente:
            </p>
            <span class="font-weight-bold font-weight-black">{{ model.cliente.NombreCompleto }}</span>
          </div>
        </v-col>
        <v-col cols="2">
          <div class="title">
            <p class="black--text font-weight-bold ma-0">
              Facturar:
            </p>
            <span class="font-weight-bold font-weight-black">
              {{ (model.caracteristicas.facturar)? 'SI' : 'NO' }}
            </span>
          </div>
        </v-col>
        <v-col cols="4" v-if="model.caracteristicas.facturar">
          <div class="title">
            <p class="black--text font-weight-bold ma-0"> Uso de CFDI </p>
            <span class="font-weight-bold font-weight-black">
              {{ model.last_movement.detalle.descripcion.concepto }}
            </span>
          </div>
        </v-col>

      </v-row>
      <v-row dense>
        <v-col cols="4">
          <div class="title">
            <p class="black--text font-weight-bold ma-0">
              Caja:
            </p>
            <span class="font-weight-bold font-weight-black">{{ model.caja.nombre }}</span>
          </div>
        </v-col>
        <v-col cols="12">
          <v-row dense>
            <v-col cols="4">
              <div class="text-center text-h5">
                <p class="black--text font-weight-bold ma-0">
                  Importe
                </p>
                <span class="font-weight-bold font-weight-black">$ {{ intlRound(model.subtotal) }}</span>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="text-center text-h5">
                <p class="black--text font-weight-bold ma-0">
                  Impuesto
                </p>
                <span class="font-weight-bold font-weight-black">$ {{ intlRound(model.impuesto) }}</span>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="text-center text-h5">
                <p class="black--text font-weight-bold ma-0">
                  Total
                </p>
                <span class="font-weight-bold font-weight-black">$ {{ intlRound(model.total) }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <DataTableMain
            :datatable="table"
            :on-success="successAction"
            :items-action="tableAction"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import DataTableMain from '@/components/tables/DataTableMain'
import { Permission } from '@/mixins/permissionMain'
import { NumberUtils } from '@/mixins/NumberMixin'
export default {
  name: 'VentasDetails',
  components: {
    DataTableMain
  },
  mixins: [Permission, NumberUtils],
  props: ['model', 'onSuccess'],
  data: () => ({
    table: {
      header: {
        options: [],
        titles: [
          { text: 'Codigo Barras', value: 'articulo.caracteristicas.codigo', class: 'black--text' },
          { text: 'Descripcion', value: 'articulo.caracteristicas.descripcion', class: 'black--text' },
          { text: 'Cantidad', value: 'cantidad', class: 'black--text' },
          { text: 'Unidad', value: 'articulo.udm.nombre', class: 'black--text' },
          { text: 'Facturar', value: 'facturar', class: 'black--text' },
          { text: 'Precio', value: 'precio_unitario', class: 'black--text' },
          { text: 'Importe', value: 'VenImporte', class: 'black--text' },
          { text: 'Impuesto', value: 'DinamicImporte', fieldMain: 'impuesto', class: 'black--text' },
          { text: 'Total', value: 'VenTotal', class: 'black--text' },
          { text: '', value: 'ActionsGral', class: 'black--text' }
        ],
        loading: false,
        showSearch: true
      },
      body: {
        data: [],
        actions: [
          // { title: 'Editar', icon: 'mdi-pencil', color: 'warning', action: 'UpdateDetailData', code: 'venta.detail-update' },
          { title: 'Eliminar', icon: 'mdi-delete', color: 'error', action: 'deleteDetailData', code: 'venta.detail-delete' }
        ]
      },
      footer: {
        paging: true
      }
    }
  }),
  watch: {
    model (row) {
      if (row.detalles) {
        // console.log('detalles', row.detalles)
        this.table.body.data = row.detalles
      }
    }
  },
  mounted () {
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    successAction (item) {
      item.item = this.model
      this.onSuccess(item)
    },
    tableAction (item) {
      this.onSuccess(item)
    }
  }
}
</script>

<style>

</style>
