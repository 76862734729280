<template>
  <div>
    <v-toolbar color="white">
      <v-toolbar-items class="hidden-sm-and-down">
        <span
          v-for="(item, index) in datatable.header.options"
          :key="index"
          class="pt-4"
        >
          <span v-if="item.hidden == ''">
            <v-btn
              v-if="item.action"
              small
              :color="item.color"
              dark
              class="mr-1"
              @click="action(item)"
            >
              {{ item.title }}
              <v-icon
                v-if="item.icon"
                right
                dark
              >{{ item.icon }}</v-icon>
            </v-btn>
            <v-menu
              v-else-if="item.options"
              offset-y
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  color="teal darken-1"
                  class="white--text"
                  v-bind="attrs"
                  small
                  v-on="on"
                >
                  {{ item.title }} <v-icon
                    right
                    dark
                  >{{ item.icon }}</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <span v-for="(option, index) in item.options" :key="index">
                  <v-list-item v-if="option.hidden == ''" link dense @click="action(option)">
                    <v-list-item-icon>
                      <v-icon>{{ option.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle>{{ option.title }}</v-list-item-subtitle>
                  </v-list-item>
                </span>
              </v-list>
            </v-menu>
          </span>
        </span>
      </v-toolbar-items>
      <span class="hidden-md-and-up">
        <v-menu :close-on-content-click="false">
          <template #activator="{ attrs, on }">
            <v-app-bar-nav-icon
              v-bind="attrs"
              small
              v-on="on"
            />
            <!--
                <v-btn color="teal darken-1" class="white--text"
                    v-bind="attrs" v-on="on" small> <v-icon dark>mdi-cog</v-icon>
                </v-btn>-->
          </template>
          <v-list dense>
            <span
              v-for="(item, index) in datatable.header.options"
              :key="index"
            >
              <span v-if="item.hidden == ''">
                <v-list-item
                  v-if="item.action"
                  link
                  dense
                  @click="action(item)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-group
                  v-else
                  :value="true"
                  no-action
                  sub-group
                >
                  <template #activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }} <v-icon
                        right
                        dark
                      >{{ item.icon }}</v-icon></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(option, index) in item.options"
                    :key="index"
                    link
                    dense
                    @click="action(option)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ option.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle>{{ option.title }}</v-list-item-subtitle>
                  </v-list-item>

                </v-list-group>
              </span>
            </span>
          </v-list>
        </v-menu>
      </span>

      <v-spacer />

      <v-text-field
        v-if="!datatable.header.showSearch"
        v-model="buscar"
        label="Buscar"
        class="inputSearch"
        clearable
        outlined
        dense
      />
    </v-toolbar>

    <v-data-table
      v-model="datatable.modelMain"
      :item-key="datatable.itemKey"
      :show-select="datatable.showSelect"
      :single-select="datatable.singleSelect"
      :headers="datatable.header.titles"
      :items="datatable.body.data"
      :hide-default-footer="datatable.footer.hideFooter"
      :footer-props="footer"
      :search="buscar"
      :loading="datatable.header.loading"
      loading-text="Cargando por favor espere"
      :item-class="rowClassColor"
      class="elevation-1"
      dense
    >
      <template #no-data>
        <v-alert
          :value="true"
          color="info"
          class="ma-1"
          border="left"
          type="info"
          icon="warning"
          dense
        >
          Lo siento, no hay registros para mostrar :(
        </v-alert>
      </template>
      <template #footer.page-text="props">
        {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>

      <template #item.Status="{ item }">
        <v-chip
          v-if="item.last_movement"
          class="font-weight-bold text-uppercase"
          :color="item.last_movement.estatus.color"
          outlined
          label
          small
        >
          {{ item.last_movement.estatus.estatus }}
        </v-chip>
      </template>
      <template #item.facturar="{ item }">
        <v-chip class="font-weight-bold text-uppercase" :color="(item.facturar === 'si') ? `info` : `warning`" outlined label small>
          {{ item.facturar }}
        </v-chip>
      </template>
      <template #item.EstatusFacturado="{ item }">
        <v-chip class="font-weight-bold text-uppercase" color="info" outlined label small>
          <span v-if="item.inventarios.facturado"> SI </span>
          <span v-else> NO </span>
        </v-chip>
      </template>
      <template #item.Facturado="{ item }">
        <v-chip class="font-weight-bold text-uppercase" color="warning" outlined label small>
          <span v-if="item.log.facturar">SI</span>
          <span v-else>NO</span>
        </v-chip>
      </template>
      <template #item.StatusMov="{ item, header }">
        <v-chip v-if="header.fieldMain" class="font-weight-bold text-uppercase" color="info" outlined label small>
          {{ item[header.fieldMain] }}
        </v-chip>
        <v-chip v-else-if="item.estatus" class="font-weight-bold text-uppercase" :color="item.estatus.color" outlined label small>
          {{ item.estatus.estatus }}
        </v-chip>
      </template>
      <template #item.Activo="{ item }">
        <v-chip class="ma-1" :color="(item.activo == 'si')? 'success':'error'" outlined small label>
          <span class="font-weight-bold text-uppercase">{{ item.activo }}</span>
        </v-chip>
      </template>
      <template #item.created_date="{ item }">
          <span >{{ item.created_at.substring(0,10)}}</span>
      </template>
      <template #item.total_amount="{ item }">
          <span >${{ item.total }}</span>
      </template>
      <template #item.EditCantidad="{ item }" >
        <v-icon color="warning" class="mx-1" v-if="item.articulo.udm.desarmable <= 0"
          @click="items({action:'deleteItem', item:item, element_action:'subtract_element'})">mdi-minus-circle
        </v-icon>

        <v-chip v-if="item.articulo.udm.desarmable > 0" class="ma-2" color="orange"
          @click="items({ action:'SetQuantityArticleSale', item:item, element_action:'update_element'})"
           label outlined>
          {{item.cantidad}}
        </v-chip>
        <span v-else class="text-h6" >{{item.cantidad}}</span>
        <v-icon color="primary" class="mx-1" v-if="item.articulo.udm.desarmable <= 0"
          @click="items({action:'addArticulo', item:item.articulo.id, element_action:'add_element'})">mdi-plus-circle
        </v-icon>
      </template>
      <template #item.ChangepricesArticle="{ item, header }" >
        <v-row dense justify="center">
          <v-col cols="auto">
            <v-text-field v-model.number="item[header.fieldMain]" :label="header.title" 
              @keydown="sendEventPrecio($event, 'AplicarPrecio', item)" outlined dense />
          </v-col>
          <!--
          <v-col cols="auto">
            <v-btn color="success" @click="items({ action: 'AplicarPrecio', item: item })">
              <v-icon left>mdi-check</v-icon> Aplicar
            </v-btn>
          </v-col>
          -->
        </v-row>
      </template>
      <template #item.unit_edit="{ item }" class="my-1">
        <div
        class="selectdd">
        <span v-if="Array.isArray(item.articulo.udm)">
          <v-col
            class="d-flex"
            cols="1"
            sm="12"
          >
            <v-select 
            style="margin-bottom: -20px;  margin-left: -10px !important; margin-right: -200px; width: 100%;"
            :items="item.articulo.udm"
            :v-model="udm_selected"
            item-text="nombre"
            item-value="id"
            @change="changeUdm"
            return-object
            outlined
            dense
            />
          </v-col>
          
        </span>
        <span v-else>{{item.articulo.udm.nombre}}</span>
        </div>
      </template>
      <template #item.DinamicImporte="{ item, header }">
        <span class="black--text font-weight-bold"> $ {{  intlRound(item[header.fieldMain]) }}</span>
      </template>

      <template #item.DinamicObject="{ item, header }">
        <span v-if="header.type === 'json'">{{ JSON.stringify(item[header.fieldMain]) }}</span>
        <span v-else-if="header.type === 'number'" class="black--text font-weight-bold"> $ {{ intlRound( ShowDataObject(item, header) ) }}</span>
        <span v-else-if="header.type === 'text'" class="black--text font-weight-bold"> {{ ShowDataObject(item, header) }}</span>
        <span v-else-if="header.type === 'textcenter'" class="black--text font-weight-bold text-center"> {{ ShowDataObject(item, header) }}</span>
        <v-chip v-else-if="header.type === 'chip'" class="font-weight-bold text-uppercase" color="info" outlined label small>
          {{ ShowDataObject(item, header) }}
        </v-chip>
        <span v-else> {{ ShowDataObject(item, header.fieldMain) }}</span>
      </template>
      <template #item.DinamicObjectList="{ item, header }">
        <span v-if="header.type === 'number'" class="black--text font-weight-bold"> $ {{ intlRound( ShowDataObjectList(item, header) ) }}</span>
        <span v-else> {{ ShowDataObjectList(item, header.fieldMain) }}</span>
      </template>

      <template #item.VenTotal="{ item }">
        <span class="black--text font-weight-bold">
          $ {{ intlRound((item.precio_unitario * item.cantidad) + item.impuesto) }}
        </span>
      </template>
      <template #item.VenImporte="{ item }">
        <span class="black--text font-weight-bold">
          $ {{ intlRound((item.precio_unitario * item.cantidad)) }}
        </span>
      </template>
      <template v-slot:item.AccessAll="{ item }">
        <span v-if="item.descripcion">
          <v-chip v-if="item.descripcion.special" class="ma-1" color="info" label outlined> {{ item.descripcion.special }} </v-chip>
          <v-chip v-else class="ma-1" color="info" label outlined> Normal </v-chip>
        </span>
        <span v-else>
          <v-chip class="ma-1" color="error" dark outlined> Sin Acceso </v-chip>
        </span>
      </template>
      <template v-slot:item.InventarioAjuste="{ item, header }">
        <span v-if="header.checkin">
          <label>{{ item.inventarios.actual.checkin }}</label> / 
          <label>{{ item.inventarios.diferente.checkin }}</label>
        </span>
        <span v-else-if="header.stock">
          <label>{{ item.inventarios.actual.stock }}</label> / 
          <label>{{ item.inventarios.diferente.stock }}</label>
        </span>
        <span v-else-if="header.picking">
          <label>{{ item.inventarios.actual.picking }}</label> / 
          <label>{{ item.inventarios.diferente.picking }}</label>
        </span>
        <span v-else-if="header.total">
          <label>{{ item.inventarios.actual.total }}</label> / 
          <label>{{ item.inventarios.diferente.total }}</label>
        </span>
        <span v-else-if="header.facturado">
          <label>{{ item.inventarios.actual.invoiced }}</label> / 
          <label>{{ item.inventarios.diferente.invoiced }}</label>
        </span>
        <span v-else-if="header.nofacturado">
          <label>{{ item.inventarios.actual.unbilled }}</label> / 
          <label>{{ item.inventarios.diferente.unbilled }}</label>
        </span>
      </template>
      <template v-slot:item.ComponentTextRow="{ item, header }">
        <ViewTextRowTable :setterModel="item" :itemAction="header.fieldMain" />
      </template>
      <template v-slot:item.Udm="{ item, header }">
        <v-chip class="font-weight-bold text-uppercase" color="info" outlined label small>
          {{ item.udm.nombre }}
        </v-chip>
      </template>


      <template #item.Actions="{ item }">
        <span>
          <v-menu offset-y>
            <template #activator="{ attrs, on }">
              <v-btn
                color="teal darken-1"
                class="white--text ma-1"
                v-bind="attrs"
                small
                v-on="on">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <!-- Acciones procesos -->
              <v-list-item-group>
                <span v-if="item.last_movement && item.last_movement.actions.length > 0">
                  <v-list-item
                    v-for="(action, index) in item.last_movement.actions"
                    :key="index"
                    link
                    @click="items({action:action.accion, item:item, body:action})"
                  >
                    <v-list-item-icon>
                      <v-icon :color="action.status_finish.color">mdi-decagram</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ action.status_finish.nombre }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </span>
              </v-list-item-group>

              <!-- Divisor -->
              <v-list-item dense v-if="datatable.body.actions.length > 0">
                <v-divider />
                <v-subheader class="black--text font-weight-bold text-center">Generales</v-subheader>
                <v-divider />
              </v-list-item>

              <!-- Acciones Locales -->
              <v-list-item-group>
                <span
                  v-for="(event, index) in datatable.body.actions"
                  :key="index"
                >
                  <v-list-item
                    v-if="event.hidden == ''"
                    link
                    @click="items({action:event.action, item:item, body:action})"
                  >
                    <v-list-item-icon>
                      <v-icon :color="event.color">{{ event.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title> {{ event.title }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </span>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </span>
      </template>
      <template #item.ActionsGral="{ item }">
        <span>
          <v-menu offset-y>
            <template #activator="{ attrs, on }">
              <v-btn
                color="teal darken-1"
                class="white--text ma-1"
                v-bind="attrs"
                small
                v-on="on">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <!-- Acciones Locales -->
              <v-list-item-group>
                <span v-for="(event, index) in datatable.body.actions" :key="index">
                  <v-list-item v-if="event.hidden == ''" link @click="items({action:event.action, item:item, body:action})">
                    <v-list-item-icon>
                      <v-icon :color="event.color">{{ event.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold black--text subtitle-2"> {{ event.title }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </span>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </span>
      </template>

      <template #item.Created="{ item }"> {{ convertDate(item.created_at) }} </template>
      <template #item.Updated="{ item }"> {{ convertDate(item.updated_at) }} </template>
    </v-data-table>
  </div>
</template>

<script>
import { NumberUtils } from '@/mixins/NumberMixin'
import ViewTextRowTable from  '@/components/fields/ViewTextRowTable.vue'
export default {
  name: 'TableMain',
  props: ['datatable', 'onSuccess', 'itemsAction'],
  mixins: [NumberUtils],
  data: () => ({
    expand: false,
    buscar: '',
    udm_selected: null,
    footer: {
      showFirstLastPage: true,
      'items-per-page-text': 'Registros por página',
    },
  }),
  components: {
    ViewTextRowTable
  },
  methods: {
    action(item) {
      this.onSuccess(item);
    },
    items(item) {
      this.itemsAction(item);
    },
    events(header, item) {
      return this[header.action](header.field, item);
    },
    rowClassColor (item) {
      if (this.datatable.class_items) {
        return this.itemsAction({ action: 'rowColor', item: item })
      }
    },
    convertDate(date) {
      if (date) {
        const dateString = new Date(date).toISOString().substr(0, 10);
        const timeString = new Date(date).toLocaleTimeString();
        return `${dateString} ${timeString}`;
      }
      return 'Sin Fecha';
    },
    AsistenciaHorasFaltas(val, item) {
      if (item.especial !== null && item.especial.horas) {
        return `${item[val]} Horas`;
      }
      return `${item[val]} Dias`;
    },
    ProcesadoAsistencias(val, item) {
      return (item.especial[val]) ? 'Aplicado' : 'No Aplicado';
    },
    setActiveRow(obje) {
      this.itemsAction(obje);
    },
    itemText(item) {
      // console.log("UDM name",item);
      return item.nombre
    },
    itemValue(item) {
      // console.log("UDM value",item);
      return item.id
    },
    changeUdm(value){
      this.items({action: "changeUdm", item:value})
    },
    ShowDataObject (item, header) {

      const arbol = header.fieldMain.split('.');
      let data = null
      arbol.map(i => {
        if (data) data = data[i]
        else data = item[i]
      })
      if (header.type === 'number' || header.type === 'textcenter') return (data) ? data: 0

      return data;
    },
    ShowDataObjectList (item, header) {
      const listmain = item[header.fieldMainList]
      let data = null
      if (header.fieldMainList === 'precios') {
        listmain.map(itemMain => {
          if (header.text === itemMain.tipo_precio.nombre) {
            data = itemMain[header.fieldMain]
          }
        })
        return (data) ? data: 0
      }

      return 0
    },
    sendEventPrecio (event,action,item) {
    this.items({ action: action, item: item })
    }
  },
};
</script>

<style>
.inputSearch{
  padding-top: 25px !important;
}

.select-udm{
  border: 1px red solid;
  display: flex !important;
  align-items: center !important;
  width: 50%;
}
</style>
