<template>
  <div>
    <component :is="type" :model="object" :on-success="actions" />
  </div>
</template>

<script>
import VentasDetails from '@/components/details/VentasDetails'
import ComprasDetails from '@/components/details/ComprasDetails'
import ArticulosDetails from '@/components/details/ArticulosDetails'
import CajasVentasDetails from '@/components/details/CajasVentasDetails'
import AjusteInventarioDetails from '@/components/details/AjusteInventarioDetails.vue'

import UsuarioDetails from '@/components/details/acceso/UsuarioDetails'
import RolPermisosDetails from '@/components/details/acceso/RolPermisosDetails'
import HistoryMovementsDetails from '@/components/details/acceso/HistoryMovementsDetails'


export default {
  components: {
    VentasDetails,
    ComprasDetails,
    ArticulosDetails,
    CajasVentasDetails,
    AjusteInventarioDetails,

    UsuarioDetails,
    RolPermisosDetails,
    HistoryMovementsDetails
  },
  props: ['object', 'type', 'itemsAction'],
  data: () => ({}),
  methods: {
    actions (actons) {
      this.itemsAction(actons)
    }
  }
}
</script>

<style>

</style>
