<template>
  <v-card class="mx-auto" elevation="0" title>
      <v-card-title class="pa-1 ma-1 justify-center"> <h2>{{itemAction.name}}</h2></v-card-title>
      <v-virtual-scroll :items="listMain" :item-height="140" min-height="350" >
        <template v-slot:default="{ item }">
          <v-row dense class="pa-1 ma-1">
            <v-col cols="12">
              <label class="font-weight-bold black--text">
                {{ itemText(item) }}
              </label>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <label class="subtitle-1 black--text font-weight-bold">Sistema</label>
              <v-row dense>
                <v-col cols="3">
                  <v-text-field v-model.number="item.inventarios.actual.checkin" label="Entrada" readonly outlined dense />
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.number="item.inventarios.actual.stock" label="Stock" readonly outlined dense />
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.number="item.inventarios.actual.picking" label="EnVenta" readonly outlined dense />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <label class="subtitle-1 black--text font-weight-bold">Importado</label>
              <v-row dense>
                <v-col cols="3">
                  <v-text-field v-model.number="item.inventarios.diferente.checkin" label="Entrada" type="number" min="0"
                    @keyup="confirmCheck({item: item, lugar: 'checkin'})"
                    outlined dense />
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.number="item.inventarios.diferente.stock" label="Stock" type="number" min="0"
                    @keyup="confirmCheck({item: item, lugar: 'stock'})"
                    outlined dense />
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.number="item.inventarios.diferente.picking" label="EnVenta" type="number" min="0"
                    @keyup="confirmCheck({item: item, lugar: 'picking'})"
                    outlined dense />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
            <v-divider />
        </template>
      </v-virtual-scroll>
  </v-card>
</template>
<script>
  export default {
    props: ['itemAction', 'onSuccess', 'setterModel'],
    data: () => ({
      listMain: [],
      catalog: [],
      deleteds: [],
      showCostActivity: false,
      searchMain: ''
    }),
    created () {
      this.seterModel(this.setterModel)
    },
    methods: {
      itemText (item) {
        if (item && this.itemAction.nameid === 'articulos') {
            return `${item.articulo.folio} - ${item.articulo.caracteristicas.descripcion}`
        }
        return `${item.nombre}`
      },
      validarRecepcion (item) {
        item.recepcion_faltante = 0
        return item.cantidad - item.cantidad_recepcion
      },
      seterModel (val) {
        if (val) {
          this.listMain.push(...val)
        }
      },
      confirmCheck ({item, lugar}) {
        this.onSuccess({
          id: this.itemAction.nameid,
          data: this.listMain,
          fieldMain: this.itemAction
        })
      }
    },
    watch: {  
    }
  }
  </script>
  
  <style>
  
  </style>
  