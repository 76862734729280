<template>
  <v-card v-if="model.id" class="elevation-2" tile>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-card tile>
            <v-card-title>
              <p class="text-h4 font-weight-bold text-center ma-auto"> Unidades de Venta</p>
            </v-card-title>
            <v-card-text>
              <v-row no-gutters dense>
                <v-col cols="3">
                  <!-- <SelectedDinamicField :setter-model="object.almacen_id" :item-action="SelectedAlmacenField" :on-success="actionFieldData" /> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction" />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <p class="text-h4 text-center ma-auto"> Precios </p>
          <DataTableMain :datatable="tablePrecios" :on-success="successActionSub" :items-action="tableAction" />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <p class="text-h4 text-center ma-auto"> Ofertas </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DataTableMain from '@/components/tables/DataTableMain'
import { Permission } from '@/mixins/permissionMain'
export default {
  name: 'ArticulosDetails',
  mixins: [Permission],
  components: {
    DataTableMain
  },
  props: ['model', 'onSuccess'],
  data: () => ({
    object: {},
    SelectedAlmacenField: {
      field: 'selectDataServer',
      name: 'Almacen',
      nameid: 'almacen_id',
      url: '/inventario/almacenes/list-sucursal',
      parmas: '',
      cols: 3,
    },
    table: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      header: {
        options: [
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newDataArticulo', code: 'inventario.options.toCheckIn' },
        ],
        titles: [
        { text: 'Folio', value: 'folio', class: 'black--text' },
        { text: 'Codigo Barras', value: 'caracteristicas.codigo', align: 'center', class: 'black--text' },
        { text: 'Producto', value: 'caracteristicas.descripcion', class: 'black--text' },
        { text: 'Minimo del Limite', value: 'cantidad_minima', align: 'center', class: 'black--text' },
        { text: 'EsFacturado', value: 'EstatusFacturado', align: 'center', class: 'black--text' },
        { text: '# Facturado', value: 'inventarios.invoiced', align: 'center', class: 'black--text' },
        { text: '# NoFacturado', value: 'inventarios.unbilled', align: 'center', class: 'black--text' },
        { text: 'Almacen', value: 'almacen.nombre', class: 'black--text' },
        { text: 'Unidad', value: 'udm.nombre', class: 'black--text' },
        { text: 'Entrada', value: 'inventarios.checkin', align: 'center', class: 'black--text' },
        { text: 'Bodega', value: 'inventarios.stock', align: 'center', class: 'black--text' },
        { text: 'En Venta', value: 'inventarios.picking', align: 'center', class: 'black--text' },
        { text: 'Apartados ventas', value: 'inventarios.pulled_apart', align: 'center', class: 'black--text' },
        { text: '', value: 'ActionsGral', class: 'black--text' }
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [
          { title: 'Editar', icon: 'mdi-pencil', color: 'error', action: 'updateDataArticulo', code: 'inventario.articulo.update' }
        ]
      },
      footer: {
        paging: true
      }
    },
    tablePrecios: {
      header: {
        options: [
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newDataPrecioArticulo', code: 'inventario.articulos.precio.create-update' },
        ],
        titles: [
        { text: 'Tipo Precio', value: 'tipo_precio.nombre', class: 'black--text' },
        { text: 'Precio', value: 'DinamicImporte', fieldMain: 'precio', class: 'black--text' },
        { text: 'activo', value: 'Activo', class: 'black--text' },
        { text: 'Fecha Vigencia', value: 'vigencia', class: 'black--text' },
        { text: 'Fecha Creacion', value: 'Created', class: 'black--text' },
        { text: 'Fecha Actualizacion', value: 'Updated', class: 'black--text' }
        ],
        loading: false,
        showSearch: false
      },
      body: {
        data: [],
        actions: []
      },
      footer: {
        paging: true
      }
    }
  }),
  mounted () {
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.tablePrecios })
  },
  methods: {
    successAction (item) {
      item.item = this.model
      this.onSuccess(item)
    },
    tableAction (item) {
      this.onSuccess(item)
    },

    successActionSub (item) {
      item.item = this.objectDetailDetail
      this.onSuccess(item)
    },
  },
  computed: {
    objectDetailDetail () {
        if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0]
      }
      return {}
    }
  },
  watch: {
    model (row) {
      if (row.articulos) {
        this.table.modelMain = []
        if (row.articulos.length > 0){
          this.table.modelMain.push(row.articulos[0])
        }
        this.table.body.data = row.articulos
      }
    },
    objectDetailDetail (item) {
      if (item) {
        this.tablePrecios.body.data = item.precios
      }
    }
  }
}
</script>
  
  <style>  
  </style>
    