import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './registerServiceWorker'

import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import axios from 'axios'
import Cookies from 'vue-cookies'

import Navigation from './components/core/NavigationDrawer.vue'
import Toolbar from './components/core/Toolbar.vue'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueSweetalert2)
Vue.use(Cookies)

Vue.component('NavigationDrawer', Navigation)
Vue.component('ToolbarDrawer', Toolbar)

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_RUTA_API

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
