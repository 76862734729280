<template>
  <v-card v-if="model.id" class="elevation-2" tile>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6" md="6">
          <p class="text-md-h4 text-sm-h5 text-center">Roles</p>
          <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction"/>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <p class="text-md-h4 text-sm-h5 text-center">Sucursales y Almacenes</p>
          <DataTableMain :datatable="tableSucursal" :on-success="successAction" :items-action="tableAction"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DataTableMain from '@/components/tables/DataTableMain'
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [Permission],
  props: ['model', 'onSuccess'],
  name: 'UsuarioDetails',
  data: () => ({
    table: {
      header: {
        options: [
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newDataRoles', code: 'usuarios.assign-rol' }
        ],
        titles: [
          { text: 'Nombre', value: 'nombre', class: 'black--text' },
          { text: 'Acceso', value: 'descripcion.special', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: '', value: 'ActionsGral', class: 'black--text' }
        ],
        loading: false,
        showSearch: true
      },
      body: {
        data: [],
        actions: [
          { title: "Eliminar", icon: "mdi-delete", color: "error", action: "daleteDataRol", code: "usuarios.remove-rol" }
        ]
      },
      footer: {
        paging: true
      }
    },
    tableSucursal: {
      header: {
        options: [
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newDataSucursal', code: 'usuarios.assign-sucursal-almacen' }
        ],
        titles: [
          { text: 'Sucursal', value: 'nombre', class: 'black--text' },
          { text: 'Almacen Principal', value: 'ComponentTextRow', class: 'black--text', fieldMain: { typeField: 'Default', type: 'objet', url: '/inventario/almacenes', params:'?id={d1}', replace:['{d1}'], value: 'default', nameid: 'nombre'} },
          { text: 'Almacenes Adicionales', value: 'ComponentTextRow', class: 'black--text', fieldMain: { typeField: 'Almacenes', type: 'list', url: '/inventario/almacenes', params:'?ids={d1}', replace:['{d1}'], value: 'almacenes', nameid: 'nombre'} },
          // { text: 'Acceso', value: 'descripcion.special', class: 'black--text' },
          // { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: '', value: 'ActionsGral', class: 'black--text' }
        ],
        loading: false,
        showSearch: true
      },
      body: {
        data: [],
        actions: [
          { title: "Eliminar", icon: "mdi-delete", color: "error", action: "daleteDataSucursal", code: "usuarios.remove-sucursal-almacen" }
        ]
      },
      footer: {
        paging: true
      }
    }    
  }),
  components: {
    DataTableMain
  },
  mounted () {
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.tableSucursal })
  },
  watch: {
    model (row) {
      this.table.body.data = row.roles
      this.tableSucursal.body.data = row.sucursales
    }
  },
  methods: {
    successAction (item) {
      item.item = this.model
      this.onSuccess(item)
    },
    tableAction (item) {
      item.model = this.model
      this.onSuccess(item)
    }
  }
}
  </script>
  
  <style>
  
  </style>
  