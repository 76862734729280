<template>
  <v-card tile :loading="loading">
    <v-card-text>
        <v-row dense>
            <v-col v-for="(next, i) in items" :key="i" class="text-h6 text-center">
              <div>
                <p class="font-weight-bold black--text">
                    {{next.nombre}} {{ (next.id == 1) ? '+ BASE' : ''  }}
                </p>
                <p>$ {{ShowImporte(next)}}</p>
              </div>
            </v-col>
        </v-row>
    </v-card-text>
<!--
    { "field": "viewText", "name": "Efectivo + Base", "nameid": "importe", "typeString": "number", "classMain": "text-h6 text-center", "cols": 4 },
    { "field": "viewText", "name": "Tarjeta DÃ©bito", "nameid": "importe_td", "typeString": "number", "classMain": "text-h6 text-center", "cols": 4 },
    { "field": "viewText", "name": "Tarjeta CrÃ©dito", "nameid": "importe_tc", "typeString": "number", "classMain": "text-h6 text-center", "cols": 4 }
-->
  </v-card>
</template>
<script>
import axios from 'axios'
import Vue from 'vue'
import { NumberUtils } from '@/mixins/NumberMixin'
export default {
  mixins: [NumberUtils],
  props: ['itemAction', 'setterModel', 'onSuccess'],
  data: () => ({
    loading: false,
    objet: {},
    items: []
  }),
  components: {},
    created () {
      if (this.setterModel) {
        this.objet = this.setterModel
        this.getDinamicData(this.itemAction.url)
      }
    },
    methods: {
      getDinamicData (url) {
        this.loading = true
        const token = Vue.$cookies.get('token_pv')
        const header = { Authorization: `Bearer ${token}` }
        axios.get(url, { headers: header }).then((response) => {
            this.items = []
            this.items.push(...response.data)
            this.loading = false
        }).catch((error) => {
          if (error == null) console.log('Error')
        }).finally(() => {
        })
      },
      ShowImporte (item) {
        const nombre = item.nombre.toLowerCase().replace(' ','_')
        return this.intlRound(this.objet[nombre])
      }
    }
  }
  </script>

  <style scoped></style>
  