<template>
  <v-dialog v-model="get_dialogMain.dialog" :max-width="get_dialogMain.withWindows"
    :loading="loadingMain" justify-cente persistent>
    <v-toolbar color="blue darken-2" dense dark>
      <v-toolbar-title>
        <h3>{{ get_dialogMain.title }}</h3>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon dark @click="HIDDE_DIALOG_MAIN()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card outlined tile>
      <v-card-text>
        <v-row dense>
          <v-col v-if="showFieldFamily" cols="12" class="pt-3">
            <SelectedGroupDinamicField :setterModel="FamilyIdMain" :item-action="fieldFamily" :on-success="actionFieldData" />
          </v-col>
          <v-col cols="12">
            <MainGeneralForm v-if="showFormGral" :params="get_dialogMain.body"
              :form="formSecound" :model="objectSecound" :on-success="saveGral" :on-cancel="cancel"/>

            <MainProcessForm v-else-if="showFormProcess && form.status_finish"
              :form="form" :model="object" :on-success="save" :on-cancel="cancel"/>
          </v-col>
          <v-col cols="12" v-if="showTable">
            <DataTableMain :datatable="tableDialog" :on-success="tableAction" :items-action="tableAction"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="showActionDetail">
        <v-layout justify-end>
          <v-btn @click="finishEvent" color="success" class="mx-2" tile dark>
            Finalizar Productos
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MainGeneralForm from '@/components/forms/MainGeneralForm'
import MainProcessForm from '@/components/forms/MainProcessForm'

import DataTableMain from '@/components/tables/DataTableMain'
import SelectedGroupDinamicField from '@/components/fields/SelectedGroupDinamicField'
import { UserSession } from '@/store/modules/config'
import { NumberUtils } from "@/mixins/NumberMixin"
export default {
  components: {
    MainGeneralForm,
    MainProcessForm,
    SelectedGroupDinamicField,
    DataTableMain
  },
  props: ['model', 'params', 'onSuccess'],
  mixins: [NumberUtils],
  data: () => ({
    formSecound: {},
    showFormGral: false,
    objectSecound: {},
    form: {},
    showFormProcess: false,
    object: {},
    fieldFamily: {
      name: 'Familia-SubFamilia',
      nameid: 'familiaId',
      url: '/catalogo/familias/subfamilias',
      sub: 'sub_familia',
      cols: 6
    },
    showFieldFamily: false,
    FamilyIdMain: 0,
    familySelectedField: 0,
    tableDialog: {},
    showTable: false,
    showActionDetail: false,
    loadingMain: false
  }),
  mounted () {
    if (this.params.showFieldFamily) {
      if (this.model.familia_id) {
        this.FamilyIdMain = this.model.familia_id
        this.fieldFamily.readonly = true
      }
      this.showFieldFamily = true
      // if (this.params.setmodel) this.object = this.params.data
      if (this.params.setmodelsecound) this.objectSecound = this.model
    } else if (this.params.form) {
      this.formSecound = JSON.parse(JSON.stringify(this.params.form))
      this.showFormGral = true

      if (this.params.setmodel) this.objectSecound = JSON.parse(JSON.stringify(this.model))

      if (this.params.formDetail) {
        this.showTable = true
        this.showActionDetail = true
        this.tableDialog = Object.assign({}, this.params.tableData)
      }
    } else if (this.get_dialogMain.form === 'process') {
      this.showFormProcess = true
      if (this.model.action) {
        if (this.params.setmodel) this.object = this.params.data
        this.form = this.model.body
        return
      }
      if (this.params.setmodel) this.object = this.model
      this.GETOptionService({ url: this.params.urlprocess, params: '/1' })
    }
  },
  methods: {
    ...mapActions(['POSTExecuteService', 'POSTExecuteServiceMultipart', 'GETOptionService', 'GETObjectService', 'ExportDataService']),
    save (model) {
      model.empresa_id = UserSession().empresa_id
      if (this.model.item) model.id = this.model.item.id
      if (this.params.formDetail) model.detail = this.tableDialog.body.data
      if (this.params.showFieldFamily) model.caracteristicas = this.objectSecound

      if (!this.params.url) {
        this.onSuccess({ name: this.get_dialogMain.type, model: model })
        this.cancel()
        return
      }
      if (this.params.formData) {
        let formData = {}
        if (this.get_dialogMain.form === 'process') formData = this.createFormDataProcess(model)
        else formData = this.createFormData(model)

        this.POSTExecuteServiceMultipart({ url: this.params.url, data: formData })
        return
      }
      // console.log('model', model)
      this.POSTExecuteService({ url: this.params.url, data: model })
    },
    saveGral (model) {
      if (!this.params.url) {
        this.onSuccess({ name: this.get_dialogMain.type, model: model })
        this.cancel()
        return
      } else if (this.params.formDetail) {
        this.showFormGral = false
        model.subtotal = model.precio_unitario * model.cantidad
        const impuesto = this.ExecuteForm({
          formula: model.tipo_impuesto.configuracion.formula,
          parametros: model.tipo_impuesto.configuracion.parametros,
          value: [model.subtotal]
        })
        model.impuesto = impuesto
        model.importe = model.subtotal + impuesto
        // console.log('model', model)
        this.tableDialog.body.data.push(model)
        // tipo_impuesto_id: 2,
        this.objectSecound = { precio_unitario: 0, almacen_id: UserSession().almacen_id }
        this.formSecound = JSON.parse(JSON.stringify(this.params.form))
        setTimeout(() => { this.showFormGral = true }, 20)
        return
      } else if (this.params.showFieldFamily) {
        let object = { caracteristicas: {} }
        if (model.id) object.id = model.id

        object.caracteristicas.familia_id = this.familySelectedField
        object.empresa_id = UserSession().empresa_id
        this.formSecound.map(field => { object.caracteristicas[field.nameid] = model[field.nameid] })

        this.POSTExecuteService({ url: this.params.url, data: object })
        return
      } else if (this.params.exportData) {
        if (model.tipo_reporte === 'excel') {
          this.LOADING_MAIN_DIALOG(true)
          this.ExportDataService({ url: this.params.url, data: model })
          this.cancel()
          return
        }
      } else {
        // if (!model.empresa_id)
        model.empresa_id = UserSession().empresa_id
      }
      this.POSTExecuteService({ url: this.params.url, data: model })
    },
    finishEvent (model) {
      if (this.params.formDetail) {
        this.showTable = false
        this.showActionDetail = false
        this.showFormGral = false

        this.GETOptionService({ url: this.params.urlprocess, params: '/1' })
        this.object = { almacen_id: UserSession().almacen_id }
        this.showFormProcess = true
      }
    },
    actionFieldData (obj) {
      if (obj.id === 'familiaId') {
        this.FamilyIdMain = obj.data
        this.familySelectedField = obj.data
        const router = this.get_urls['GET/catalogo/familias/caracteristicas/{id}'];
        this.GETObjectService({ url: router, replace: '/{id}', params: `/${obj.data}` });
      }
    },
    createFormData (object) {
      const formData = new FormData()
      this.params.fields.map((field) => formData.append(`${field.nameid}`, object[field.nameid]))
      return formData
    },
    createFormDataProcess (object) {
      const formData = new FormData()
      Object.entries(object).forEach(([key, value]) => {
        formData.append(key, value)
      });

      Object.entries(object.descripcion).forEach(([key, value]) => {
        formData.append(key, value)
      });
      return formData
    },
    cancel () {
      this.HIDDE_DIALOG_MAIN()
    },
    tableAction (item) {
      this[item.action](item)
    },
    deleteData ({ item }) {
      console.log('item', item)
      this.tableDialog.header.loading = true
      this.tableDialog.body.data = this.tableDialog.body.data.filter(articulo => articulo.articulo.id !== item.articulo.id )
      setTimeout(() => { this.tableDialog.header.loading = false }, 500)
      // let index = -1
      // this.tableDialog.body.data.map((articulo, i) => { if (articulo.articulo.id === item.articulo.id) { index = i } })
      // if (index !== -1) { this.tableDialog.body.data.splice(index,1) }
    },
    ...mapMutations(['HIDDE_DIALOG_MAIN', 'SET_DATA', 'SET_RELOAD_DATA', 'SET_DATA_TWO', 'HIDDE_LOADING_MAIN', 'LOADING_MAIN_DIALOG'])
  },
  computed: {
    ...mapGetters(['get_urls', 'get_dialogMain', 'get_options', 'get_model', 'get_object'])
  },
  watch: {
    get_model (val) {
      this.HIDDE_LOADING_MAIN(false)
      if (val.message) {
        this.$swal({ type: 'error', icon: 'error', title: 'Oops...!', text: val.message })
        return
      }
      this.$swal({ type: 'success', icon: 'success', title: 'Existoso!!', text: this.params.message, timer: 2000 })
      if (this.params.returnObject) {
        this.onSuccess({ name: this.get_dialogMain.type, model: val })
      } else if (this.params.reload) {
        this.SET_RELOAD_DATA(val)
      }
      if (this.params.objcettwo) this.SET_DATA_TWO(val)
      else this.SET_DATA(val)

      this.cancel()
    },
    get_options (val) { if (val) this.form = val },
    get_object (data) {
      if (data) {
        this.formSecound = data.fields
        this.showFormGral = true
      }
    }
  }
}
</script>

<style></style>
