<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" sm="6" md="4">
        <v-card outlined tile>
          <v-card-title>Restructuración de Cantidades</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <SelectedDinamicField :item-action="SelectedSucursalField" :on-success="actionFieldData" />
              </v-col>
              <v-col v-if="showAlmacen" cols="12">
                <SelectedDinamicField :item-action="SelectedAlmacenField" :on-success="actionFieldData" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row dense justify="end" class="pa-1">
              <v-btn color="green darken-2" @click="ProcessConteoInventario" dark large>
                <v-icon left >mdi-cog</v-icon> <span class="subtitle-1 font-weight-bold">Ejecutar</span>
              </v-btn>
            </v-row>
          </v-card-actions>
          <v-progress-linear v-if="RestructuraconLoadding" color="success" height="10" indeterminate striped>
          </v-progress-linear>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-card outlined tile>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SelectedDinamicField from '@/components/fields/SelectedDinamicField'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'ConteoInventarios',
  data: () => ({
    RestructuraconLoadding: false,
    showAlmacen: false,
    SelectedSucursalField: { field: 'selectDataServer', name: 'Sucursales', nameid: 'sucursal_id', url: '/empresa/sucursales', parmas: '', cols: 3 },
    SelectedAlmacenField: { field: 'selectDataServer', name: 'Almacenes', nameid: 'almacen_id', url: '/inventario/almacenes/list-sucursal', parmas: '', cols: 3 },
    params: {},
    urlMain: null,
    tipoProceso: null
  }),
  components: {
    SelectedDinamicField
  },
  methods: {
    ...mapActions(['GETUrlServices', 'POSTObjectsService']),
    ...mapMutations([]),
    /* *==========*==========*==========*==========*==========*==========*==========*==========*==========*==========* */
    /* *==========*==========*==========*==========*==========*==========*==========*==========*==========*==========* */
    ProcessConteoInventario () {
      if(!this.params.sucursal_id) {
        this.$swal({ type: 'error', icon: 'error', title: 'Oops...!', text: 'Campo Sucursal no debe ser vacio' })
        return
      }
      this.RestructuraconLoadding = true
      this.tipoProceso = 'RestructuraconLoadding'
      this.ExecuteUrl('POST/inventario/rutinas/restructuracion-conteo')
    },
    actionFieldData (obj) {
      this.params[obj.id] = (obj.data)? obj.data : null;

      if (obj.id === 'sucursal_id') {
        this.params.almacen_id = null
        if (obj.data) {
          this.showAlmacen = false
          this.SelectedAlmacenField.params = `/${obj.data}`
          setTimeout(() => { this.showAlmacen = true }, 1)
        } else {
          this.sucursal_id = null
          this.SelectedAlmacenField.params = ''
          this.showAlmacen = false
        }
      }
    },
    ExecuteUrl (objet) {
      if (this.get_urls != null) {
        const router = this.get_urls[objet]
        this.POSTObjectsService({ url: router, data: this.params })
        this.urlMain = null
      } else {
        this.urlMain = objet
        this.GETUrlServices()
      }
    }
  },
  computed: {
    ...mapGetters(['get_urls', 'get_object'])
  },
  watch: {
    get_urls () {
      console.log('urlMain', this.urlMain)
      this.ExecuteUrl(this.urlMain)
    },
    get_object (response) {
      console.log(response)
      if(this.tipoProceso == 'RestructuraconLoadding') {
        if (response.message) {
          this.$swal({
            type: 'error',
            icon: 'error',
            title: 'Oops...!',
            text: val.message
          })
          return
        }
        this.$swal({
          type: 'success',
          icon: 'success',
          title: 'Existoso!!',
          text: this.params.message,
          timer: 2000
        })
        this.RestructuraconLoadding = false
        this.tipoProceso = null
        return
      }

    },
  }
}
</script>
<style>
</style>
  